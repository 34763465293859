import { simseiApi } from '@/residency/app-props'
import { arSubActivityIdToArActivityIdMapping, isArTutorial } from '@/residency/views/ar-cam-nav/consts/ar-subactivities.js'
import feedbackEnum from '@/residency/views/ar-cam-nav/consts/feedback-enum'
import log from '@/residency/utils/log'
import { defineStore } from 'pinia'
import { useMeStore } from '@/residency/stores/me'
import { useUserGroupStore } from '@/residency/stores/user-group'

function checkNotes (notes) {
  const emptyNotes = []

  // Focus, Star and Tube activity do not have any analysis notes
  if (!notes?.length) {
    return emptyNotes
  }

  // We check to see if the game sent us any notes
  // if it does, we return the original array back
  for (const note of notes) {
    if (note.note !== feedbackEnum[0]) { // feedbackEnum[0] is 'NONE'
      return notes
    }
  }

  // User has a passing attempt and the game did not
  // send us back any analysis notes.
  return emptyNotes
}

export const useArStore = defineStore('ar', {
  persist: { storage: sessionStorage },
  state: () => ({
    arHistory: {},
    arSelectedActivityId: null,
    arSelectedSubActivityId: null,
    arSelectedLevel: null,
    arLevelMetrics: null,
    isGameInProgress: false,
    isArTutorialInProgress: false,
    isArTutorialCompleted: false,
    arMetric: null,
    isViewingArHomeScreen: true
  }),
  actions: {
    setSelectedArActivity (activity) {
      this.arSelectedActivityId = activity
      this.arSelectedSubActivityId = null
    },
    async fetchArSubActivityMetrics () {
      if (this.arMetric !== null) {
        return
      }
      try {
        const resp = await simseiApi.get('/arSubActivity')
        const data = resp.data._embedded.arSubActivity

        const subActivityMetrics = {}
        for (const subActivity of data) {
          const subActivityMetricMap = {}
          subActivity.metrics.forEach(metric => {
            subActivityMetricMap[metric.name] = {
              passingScore: metric.passingScore,
              metricOrder: metric.metricOrder
            }
          })
          subActivityMetrics[subActivity.id] = subActivityMetricMap
        }

        this.arMetric = subActivityMetrics
      } catch (error) {
        log.error(error)
      }
    },
    setSelectedArSubActivity (subActivityId) {
      const activityId = arSubActivityIdToArActivityIdMapping[subActivityId]
      if (!activityId) {
        log.error(`Invalid subActivityId: ${subActivityId}`)
        return
      }
      this.setSelectedArActivity(activityId)
      this.arSelectedSubActivityId = subActivityId
      this.isViewingArHomeScreen = false
    },
    async getArOverallHistory () {
      const arCamNavActivityId = this.arSelectedActivityId
      if (!arCamNavActivityId) return

      let overallProgressResult

      const meStore = useMeStore()
      if (meStore.isInstructor) {
        overallProgressResult = await simseiApi.get(`/ar-attempt/faculty/overall-progress/${arCamNavActivityId}`)
      } else {
        const { currentUserGroupId } = useUserGroupStore()
        overallProgressResult = await simseiApi.get(`/ar-attempt/resident/overall-progress/${arCamNavActivityId}?userGroupId=${currentUserGroupId}`)
      }

      const overallProgress = overallProgressResult.data

      const arHistoryList = {}
      for (const activity of overallProgress) {
        arHistoryList[activity.subActivityId] = activity
      }

      this.arHistory = arHistoryList
      return arHistoryList
    },
    startArActivityLevel (level) {
      this.arSelectedLevel = level
      this.isGameInProgress = true
      this.arLevelMetrics = null
    },
    endArActivityLevel (metrics) {
      this.isGameInProgress = false
      this.arLevelMetrics = metrics
    },
    resetArActivity () {
      this.isViewingArHomeScreen = true
      this.arSelectedSubActivityId = null
      this.arSelectedLevel = null
      this.isArTutorialInProgress = false
      this.isArTutorialCompleted = false
    },
    submitArAttempt ({ subActivityId, level, metrics, notes, elapsedSeconds }) {
      if (this.arSelectedSubActivityId !== subActivityId && !isArTutorial(subActivityId)) {
        log.error(`Mismatch between the selected activity (${this.arSelectedSubActivityId}) in the store and activity played (subActivityId: ${subActivityId})`)
      }
      const scores = []
      for (const [key, value] of Object.entries(metrics)) {
        scores.push({
          metricType: key.toUpperCase(),
          score: value
        })
      }
      const notesList = checkNotes(notes)

      const meStore = useMeStore()
      const userType = meStore.isInstructor ? 'faculty' : 'resident'

      simseiApi.post(`/ar-attempt/${userType}/submit/` + this.arSelectedActivityId, {
        subActivityId,
        level,
        exerciseScores: scores,
        analysisNotes: notesList,
        elapsedSeconds
      })

      simseiApi.post(`/activity-guide-progress/complete-activity-guide?activityId=${this.arSelectedActivityId}`)
    },
    startArTutorial () {
      this.isArTutorialInProgress = true
      this.isArTutorialCompleted = false
      this.isViewingArHomeScreen = false
    },
    completeArTutorial (attempt) {
      this.isArTutorialInProgress = false
      this.isArTutorialCompleted = true
      this.submitArAttempt(attempt)
    },
    exitArGame () {
      this.arSelectedSubActivityId = null
      this.arSelectedLevel = null
      this.isGameInProgress = false
      this.isArTutorialInProgress = false
      this.isArTutorialCompleted = false
      this.isViewingArHomeScreen = true
    }
  }
})
