<template>
  <div ref="slotContainer" class="base-tooltip-container" v-bind="$attrs">
    <slot></slot>
  </div>
  <Teleport :to="target">
    <div
      v-if="canShow"
      v-show="isVisible"
      ref="tooltipElement"
      class="base-tooltip"
      :style="floatingStyles"
      data-cy="simseitooltip-container"
    >
      {{ content }}
      <slot name="tooltip"></slot>
      <div ref="arrowElement" :class="['base-tooltip-arrow', arrowPosition]" :style="arrowStyle"></div>
    </div>
  </Teleport>
</template>

<script>
import { ref } from 'vue'
import { useFloating, offset, flip, shift, autoUpdate, arrow } from '@floating-ui/vue'
import { isMobile } from '@/utils/detect-device-util'

export default {
  props: {
    // Tooltips with simple content can use this property for the content it should display,
    // but more complex tooltips can use the default slot.
    content: {
      type: String,
      required: false
    },
    position: {
      type: String,
      default: 'top',
      validator: (pos) => {
        return ['top', 'top-start', 'top-end', 'right', 'right-start', 'right-end',
          'left', 'left-start', 'left-end', 'bottom', 'bottom-start', 'bottom-end']
          .indexOf(pos) !== -1
      }
    },
    enableMobile: {
      type: Boolean,
      default: false
    },
    // Add an offset to the tooltip to move it away from the target element
    offset: {
      type: Number,
      default: 10
    },
    enabled: {
      type: Boolean,
      default: true
    },
    tooltipDelayMs: {
      type: Number,
      required: false,
      default: 0
    }
  },
  setup (props) {
    const targetElement = ref(null)
    const tooltipElement = ref(null)
    const arrowElement = ref(null)
    const { floatingStyles, middlewareData, placement } = useFloating(targetElement, tooltipElement, {
      placement: props.position,
      strategy: 'fixed',
      whileElementsMounted: autoUpdate,
      middleware: [offset(props.offset), flip(), shift(), arrow({ element: arrowElement })]
    })
    return {
      floatingStyles,
      targetElement,
      tooltipElement,
      arrowElement,
      middlewareData,
      placement
    }
  },
  data () {
    return {
      isVisible: false,
      timerId: null
    }
  },
  computed: {
    canShow () {
      return this.enabled && ((isMobile() && this.enableMobile) || !isMobile())
    },
    arrowPosition () {
      return `base-tooltip-arrow-${this.placement}`
    },
    arrowStyle () {
      const style = {}
      // When floating-ui gives us the arrow position, it only gives us one axis.
      // The unset axis is considered static, so we have to manually set it to the
      // opposite side of the placement.
      if (this.middlewareData.arrow?.x != null) {
        style.left = `${this.middlewareData.arrow.x}px`
      } else {
        style[this.placement.includes('right') ? 'left' : 'right'] = '-5px'
      }
      if (this.middlewareData.arrow?.y != null) {
        style.top = `${this.middlewareData.arrow.y}px`
      } else {
        style[this.placement.includes('top') ? 'bottom' : 'top'] = '-5px'
      }
      return style
    },
    target () {
      return document.getElementById('modal') ? '#modal' : 'body'
    }
  },
  mounted () {
    this.$nextTick(() => {
      const slotContainer = this.$refs.slotContainer
      if (slotContainer.children.length) {
        this.targetElement = slotContainer.children[0]
        this.tooltipElement = this.$refs.tooltipElement
        this.arrowElement = this.$refs.arrowElement
        this.targetElement.addEventListener('mouseenter', this.show)
        this.targetElement.addEventListener('mouseleave', this.hide)
        this.targetElement.addEventListener('touchstart', this.toggle)
      }
    })
  },
  methods: {
    show () {
      this.timerId = setTimeout(() => {
        this.isVisible = true
      }, this.tooltipDelayMs)
    },
    hide () {
      clearTimeout(this.timerId)
      this.isVisible = false
    },
    toggle () {
      this.isVisible = !this.isVisible
    }
  }
}
</script>

<style lang="scss" scoped>
$tooltip-bg: rgba(60, 60, 60, 0.95);
$tooltip-arrow-size: 6px;
$tooltip-arrow-offset: 7px;

.base-tooltip {
  background-color: $tooltip-bg;
  font-size: 14px;
  color: #FFFFFF;
  z-index: 99999;
  padding: 6px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 1px rgba(black, 0.25);
  pointer-events: none;
  max-width: 400px;
  font-family: "Open Sans";
}

.base-tooltip-container {
  display: inline;
}

.base-tooltip-arrow {
  position: absolute;
  width: 0px;
  height: 0px;
  border: $tooltip-arrow-size solid transparent;
}

.base-tooltip-arrow-bottom-start, .base-tooltip-arrow-bottom-end, .base-tooltip-arrow-bottom {
  border-bottom: $tooltip-arrow-size solid $tooltip-bg;
  translate: 0 (-$tooltip-arrow-offset);
}

.base-tooltip-arrow-left-start, .base-tooltip-arrow-left-end, .base-tooltip-arrow-left {
  border-left: $tooltip-arrow-size solid $tooltip-bg;
  translate: $tooltip-arrow-offset 0;
}

.base-tooltip-arrow-top-start, .base-tooltip-arrow-top-end, .base-tooltip-arrow-top {
  border-top: $tooltip-arrow-size solid $tooltip-bg;
  translate: 0 $tooltip-arrow-offset;
}

.base-tooltip-arrow-right-start, .base-tooltip-arrow-right-end, .base-tooltip-arrow-right {
  border-right: $tooltip-arrow-size solid $tooltip-bg;
  translate: -$tooltip-arrow-offset 0;
}
</style>
