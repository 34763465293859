import { defineStore } from 'pinia'
import viewModes from '@/residency/consts/resident-view-modes'
import log from '@/residency/utils/log'

export const useProctorStore = defineStore('proctor', {
  persist: { storage: sessionStorage },
  state: () => ({
    proctorGroups: [],
    expiredProctorGroups: [],
    viewMode: viewModes.RESIDENT
  }),
  getters: {
    isProctor: state => state.proctorGroups.length > 0,
    isViewingAsProctor: state => state.viewMode === viewModes.PROCTOR
  },
  actions: {
    setProctorGroups (proctorGroups) {
      if (proctorGroups) {
        this.proctorGroups = proctorGroups.filter(group => group.isActive)
        this.expiredProctorGroups = proctorGroups.filter(group => !group.isActive)
      }
    },
    changeViewType (newViewMode) {
      if (!Object.values(viewModes).includes(newViewMode)) {
        log.error(`Unknown view type: ${newViewMode}`)
        return
      }
      this.viewMode = newViewMode
    }
  }
})
