import { defineStore } from 'pinia'
import { simseiApi } from '@/residency/app-props'
import { parseEventDeletedNotification, isCardNotification } from '@/residency/utils/notification-util'
import notificationTypes from '@/residency/views/schedule/notification/notification-types'
import log from '@/residency/utils/log'
import { useMeStore } from '@/residency/stores/me'
import { useProgramStore } from '@/residency/stores/program'

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    notifications: {},
    asmtIdToNotifId: {},
    hasNewAsmtNotif: false,
    hasNewNotifCards: false
  }),
  getters: {
    isNewAsmt: state => asmtId => {
      return state.asmtIdToNotifId.hasOwnProperty(asmtId)
    }
  },
  actions: {
    setHasNewAsmt (newBool) {
      this.hasNewAsmtNotif = newBool
    },
    setHasNewNotifCards (newBool) {
      this.hasNewNotifCards = newBool
    },
    async fetchNotifications () {
      const meStore = useMeStore()
      if (!meStore.isActive || meStore.isAdministrativeRole) return
      const { programId } = useProgramStore()
      try {
        const resp = await simseiApi.get('/notification/search/findAllByUserIdAndProgramId', {
          params: {
            userId: meStore.id,
            programId: programId
          }
        })
        Object.entries(resp.data._embedded).forEach(([notifType, notifArray]) => {
          notifArray.forEach(notif => {
            switch (notif.type) {
              case notificationTypes.NEW_VIDEO_ASMT:
              case notificationTypes.NEW_ASMT:
                this.asmtIdToNotifId[notif.asmtId] = notif
                break
              case notificationTypes.EVENT:
                parseEventDeletedNotification(notif)
                break
              case notificationTypes.SHARED_LIBRARY:
                break
              case notificationTypes.REPLY:
                this.asmtIdToNotifId[notif.asmtId] = notif.id
                break
              default:
                log.error('Notification has an unrecognized type; ', notif)
            }
            this.notifications[notif.id] = notif
          })
        })
      } catch (e) {
        log.error(e)
      }
    },
    addNewWSNotif (notif) {
      switch (notif.type) {
        case notificationTypes.NEW_VIDEO_ASMT:
        case notificationTypes.NEW_ASMT:
          this.asmtIdToNotifId[notif.asmtId] = notif.id
          this.hasNewAsmtNotif = true
          break
        case notificationTypes.EVENT:
          parseEventDeletedNotification(notif)
          break
        case notificationTypes.SHARED_LIBRARY:
          break
        case notificationTypes.REPLY:
          this.asmtIdToNotifId[notif.asmtId] = notif.id
          break
        default:
          log.error('Notification has an unrecognized type; ', notif)
      }
      this.notifications[notif.id] = notif
      if (isCardNotification(notif.type)) {
        this.hasNewNotifCards = true
      }
    },
    async deleteNotification (notif) {
      const notifId = notif.id
      try {
        await simseiApi.delete(`/notification/${notifId}`)
      } catch (e) {
        log.error(e)
      }

      switch (notif.type) {
        case notificationTypes.NEW_ASMT:
          delete this.asmtIdToNotifId[notif.asmtId]
          break
        case notificationTypes.SHARED_LIBRARY:
          break
        case notificationTypes.REPLY:
          delete this.asmtIdToNotifId[notif.asmtId]
          break
        case notificationTypes.NEW_VIDEO_ASMT:
          delete this.asmtIdToNotifId[notif.asmtId]
          break
        case notificationTypes.EVENT:
          // Event notifications aren't stored in a separate map
          // so we don't need to do anything for these notifications
          break
        default:
          log.error('Unrecognized Notification Type', notif.type)
      }
      delete this.notifications[notifId]
    }
  }
})
