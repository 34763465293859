<template>
  <div class="buttons">
    <BaseButton
      v-for="(val, key) in categories"
      :key="key"
      :outlined="modelValue !== key"
      :colorScheme="getColor(key)"
      width="48%"
      data-cy="feedback-category"
      @click="$emit('update:modelValue', key)"
    >
      {{ val.text }}
    </BaseButton>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      required: false
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      categories: {
        'GENERAL': {
          text: 'GENERAL'
        },
        'BUG': {
          text: 'REPORT A BUG'
        }
      }
    }
  },
  methods: {
    getColor (category) {
      return this.modelValue === category
        ? 'dark-teal'
        : 'gray'
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button-text {
  text-transform: none;
}

.selected-text {
  color: $neutral-white;
}
</style>
