import { defineStore } from 'pinia'
import { workshopApi } from '@/workshop/workshop-api'

export const useAdminUserStore = defineStore('adminUser', {
  state: () => ({
    administrativeUsers: [],
    roles: []
  }),
  actions: {
    async fetchAdministrativeUsers () {
      const users = await workshopApi.get('/admin-user/administrative')
      this.administrativeUsers = users.data
    },
    async fetchRoles () {
      if (!this.roles.length) {
        const roles = await workshopApi.get('/workshop-role')
        this.roles = roles.data._embedded.workshopRole
      }
    },
    submitAdministrativeUser (user) {
      const userData = {
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
        email: user.email,
        isInternal: user.isInternal
      }
      if (user.phoneNumber) {
        userData.userDetails = { phone: { number: user.phoneNumber } }
      }
      return workshopApi.post(`/admin-user/administrative?send-email=${user.sendRegistrationLink}`, userData)
    },
    async updateAdministrativeUser (user) {
      const userData = {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
        email: user.email,
        isInternal: user.isInternal,
        userDetails: {
          phone: user.phoneNumber ? { number: user.phoneNumber } : null
        }
      }

      await workshopApi.put('/admin-user/administrative', userData)
      this.fetchAdministrativeUsers()
    },
    async deleteAdministrativeUser (user) {
      await workshopApi.delete(`/admin-user/administrative/${user.id}`)
      this.fetchAdministrativeUsers()
    },
    findAdministrativeUserByRegistrationToken (token) {
      return workshopApi.get(`/admin-user/administrative-unregistered-user?token=${token}`)
    },
    registerAdministrativeUser ({ registrationToken, user }) {
      return workshopApi.patch(`/admin-user/register-administrative-user?token=${registrationToken}`, user)
    },
    sendRegistrationEmail (user) {
      return workshopApi.post('/admin-user/send-registration-email', user)
    }
  }
})
