import cssVariables from '@/assets/scss/_variables.module.scss'

export const mobileThreshold = Object.freeze({
  SMALL: parseInt(cssVariables.sm),
  MEDIUM: parseInt(cssVariables.md),
  LARGE: parseInt(cssVariables.lg),
  EXTRA_LARGE: parseInt(cssVariables.xl),
  MOBILE: parseInt(cssVariables.maxMobileWidth),
  TABLET: 950,
  CALENDAR_PAGE: 1024,
  DASHBOARD_ACTIVITY_STATUS: 1279,
  ACCOUNT_PAGE: 968
})
