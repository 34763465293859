<template>
  <div class="search-bar">
    <input
      :id="id"
      :value="modelValue"
      :autofocus="autofocus"
      :style="styleWidth"
      :placeholder="placeholder"
      :data-cy="dataCy"
      class="icon-right"
      data-testid="base-input-search"
      @input="handleInput"
    />
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      requird: false
    },
    modelValue: {
      required: false
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    width: {
      type: [String, Number],
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    dataCy: {
      type: String,
      required: false
    }
  },
  emits: ['update:modelValue'],
  computed: {
    styleWidth () {
      if (!this.width) return ''
      if (isNaN(this.width)) return `width:${this.width}` // cases such as '3rem', '10%', etc.
      return `width:${this.width}px`
    }
  },
  methods: {
    handleInput (event) {
      event.target.value = this.formatter ? this.formatter(event.target.value) : event.target.value
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/components/inputs/input.scss";

input {
  @include form-control;
}

.icon-right {
  background: $surface-color url("~@/assets/icons/search-bar-icon.svg") no-repeat;
  background-position: right 12px center !important;
  padding-left: 12px;
  padding-right: 32px;
  padding-bottom: 10px;
  padding-top: 8px;
}
</style>
