<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="!isFormSubmitted"
      class="form-layout"
      :class="{ 'form-desktop-width': !isMobile}"
    >
      <ModalCloseButton @close="$emit('close')" class="close-button"/>
      <BaseForm class="form">
        <div class="form-title">
          <h1 class="form-title-text">
            Feedback
          </h1>
          <p class="form-title-subtext">
            Thanks for helping us improve!
          </p>
        </div>
        <div class="form-stars">
          <p class="form-text">
            How was your experience today?
          </p>
          <FiveStar v-model="form.stars"/>
        </div>
        <div class="form-category">
          <p class="form-text form-text-margin">
            Please select a category
          </p>
          <FeedbackFormCategory v-model="form.type" />
        </div>
        <div class="form-more">
          <p class="form-text form-text-margin">
            Tell us more...
          </p>
          <v-textarea
            class="feedback-text"
            v-model="form.text"
            variant="outlined"
            placeholder="Tell us more"
            maxlength="1024"
            rows="4"
            auto-grow
            data-cy="feedback-text"
          ></v-textarea>
        </div>
        <BaseButton
          :disabled="!validForm"
          class="form-submit"
          colorScheme="light-teal"
          data-cy="submit-feedback-btn"
          @click="submit"
        >
          SUBMIT
        </BaseButton>
      </BaseForm>
    </div>
    <div
      v-else
      class="form-layout form"
      :class="{ 'form-confirm-desktop-width': !isMobile}"
    >
      <ModalCloseButton @close="$emit('close')" class="close-button"/>
      <img class="checkmark" src="@/assets/icons/alert-success-large.svg" >
      <h1 class="form-title-text" data-cy="form-title-text">
        Success
      </h1>
      <p class="form-title-subtext">
        Thank you for your feedback!
      </p>
    </div>
  </transition>
</template>

<script>
import ModalCloseButton from '@/components/buttons/ModalCloseButton'
import FiveStar from '@/components/site-feedback/FiveStar'
import FeedbackFormCategory from '@/components/site-feedback/FeedbackFormCategory'
import { useMobileThreshold } from '@/composables/useMobileThreshold'

export default {
  components: {
    ModalCloseButton,
    FiveStar,
    FeedbackFormCategory
  },
  emits: ['submit', 'close'],
  props: {
    isFormSubmitted: {
      type: Boolean
    }
  },
  setup () {
    const { isMobile } = useMobileThreshold()
    return { isMobile }
  },
  data () {
    return {
      form: {
        stars: 0,
        type: null,
        text: ''
      }
    }
  },
  computed: {
    validForm () {
      return this.form.stars > 0 &&
        this.form.stars <= 5 &&
        this.form.type &&
        this.form.type !== ''
    }
  },
  methods: {
    async submit () {
      const form = {
        route: this.$route.fullPath,
        stars: this.form.stars,
        type: this.form.type,
        text: this.form.text
      }
      this.$emit('submit', form)
    }
  }
}
</script>

<style lang="scss" scoped>
.form-layout {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.form-desktop-width {
  width: 475px;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-title-text {
  color: $neutral-typography-dark;
  text-align: center;
  margin-bottom: 3px;
}

.form-title-subtext {
  color: $neutral-typography-medium;
  font-size: 13px;
  text-align: center;
}

.close-button {
  align-self: flex-end;
  margin: 0px;
}

.form-text {
  font-size: 15px;
  color: $neutral-typography-dark;
  margin: 0px;
  font-family: "Open Sans";
}

.form-text-margin {
  margin-bottom: 5px;
}

.form-stars {
  margin-top: 20px;
}

.form-category {
  margin-top: 15px;
}

.form-more {
  margin-top: 25px;
}

.form-submit {
  width: 100px;
  align-self: center;
}

.form-confirm-desktop-width {
  width: 300px;
}

.feedback-text {
  width: 100%;
}

.checkmark {
  align-self: center;
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}
</style>
