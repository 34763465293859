/**
 * Performs a comparison of two objects, returning true if they are equal. Be careful with running this function on
 * objects that are deeply nested, as it will recurse through all of the properties of the objects. This
 * will return true when comparing nulls and arrays, but will return false when comparing different types.
 *
 * @param {Object} objA
 * @param {Object} objB
 * @returns {boolean} True if the objects are equal, false otherwise
 * @throws {TypeError} If either object is self-referential or contains a circular reference
 */
export function objectsAreEqual (objA, objB) {
  // Test that both objects are objects
  if (typeof objA !== 'object' || typeof objB !== 'object') {
    return false
  }

  // Referential test and null/undefined test
  if (objA === objB) {
    return true
  }

  // Test that both objects have the same prototype
  if (Object.getPrototypeOf(objA) !== Object.getPrototypeOf(objB)) {
    return false
  }

  const keysA = Object.keys(objA)
  const keysB = Object.keys(objB)

  // Test that both objects have the same number of keys
  if (keysA.length !== keysB.length) {
    return false
  }

  // Test that all keys in objA are in objB and that they are the same type
  for (const key of keysA) {
    if (!objB.hasOwnProperty(key)) {
      return false
    }

    if (typeof objA[key] !== typeof objB[key]) {
      return false
    }
  }

  // Stringify the objects to rely on the javascript engine to check
  // if there are circular references for us. If there are, this will
  // throw a TypeError.
  JSON.stringify(objA)
  JSON.stringify(objB)

  // Test that the key-values are the same
  for (const key of keysA) {
    if (Array.isArray(objA[key]) && Array.isArray(objB[key])) {
      if (!areArraysEqual(objA[key], objB[key])) {
        return false
      }
      continue
    }

    if (isObject(objA[key]) && isObject(objB[key])) {
      // If both values are objects, recurse
      if (!objectsAreEqual(objA[key], objB[key])) {
        return false
      }
      continue
    }

    if (objA[key] !== objB[key]) {
      return false
    }
  }

  return true
}

function isObject (obj) {
  return Object.getPrototypeOf(obj) === Object.prototype
}

function areArraysEqual (arrayA, arrayB) {
  if (arrayA.length !== arrayB.length) {
    return false
  }

  for (let i = 0; i < arrayA.length; i++) {
    // Recurse for array of arrays
    if (Array.isArray(arrayA[i]) && Array.isArray(arrayB[i])) {
      if (!areArraysEqual(arrayA[i], arrayB[i])) {
        return false
      }
      continue
    }

    if (isObject(arrayA[i]) && isObject(arrayB[i])) {
      if (!objectsAreEqual(arrayA[i], arrayB[i])) {
        return false
      }
      continue
    }

    if (arrayA[i] !== arrayB[i]) {
      return false
    }
  }

  return true
}
