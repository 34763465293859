import { defineStore } from 'pinia'
import { simseiApi } from '@/residency/app-props'
import { eventTypes } from '@/residency/views/schedule/schedule-enums'
import { parseDateAndTimeForProgram, parseLocaleDate } from '@/residency/utils/residency-date-util.js'
import { convertToUtc } from '@/utils/date-util'
import { binarySearch } from '@/residency/utils/store-util'
import userRoles from '@/residency/consts/user-roles'
import { useMeStore } from '@/residency/stores/me'
import { useUserStore } from '@/residency/stores/user'
import { useProctorStore } from '@/residency/stores/proctor'
import { useAdminStore } from '@/residency/stores/admin/admin'
import { useAdminManageUsersStore } from './admin/admin-manage-users'
import { randomAlphanumericString } from '@/utils/string-util'

const getEventFormState = () => {
  return {
    id: null,
    title: null,
    location: null,
    startDate: null,
    startTime: null,
    endTime: null,
    notes: '',
    attachments: [],
    facultyAttendees: [],
    proctorAttendees: [],
    simAttendees: [],
    ftmAttendees: [],
    userGroupAttendees: new Set(),
    residentAttendees: new Set(),
    activities: [],
    programId: null
  }
}

function binarySearchEvent (sortedEvents, event) {
  return binarySearch(sortedEvents, event, (event1, event2) => event1.startDate - event2.startDate)
}

function formatActivity (activities) {
  const formattedActivities = []

  activities.forEach((activity) => {
    formattedActivities.push({ activity: { id: activity.id }, activityType: activity.activityType })
  })

  return formattedActivities
}

/**
 * Calculates the dates from a given start point (now) to some amount of days in the past. For example, this method can
 * be used to compute what date is 70 days in the past from Jan 1st 2020.
 *
 * @param now - When to compute dates in the past relative to ("now" is exclusive, but "now - daysBefore" is inclusive)
 * @param daysBefore - How many days in the past the range should include
 * @returns {{endDate: string, queryString: string, startDate: string}} - Returns an object with information about the
 * date range. The object also contains a query string to be used with endpoints that load events over a date range
 */
function getDateRange (now, daysBefore) {
  const startDate = new Date(now - daysBefore * 24 * 60 * 60 * 1000).toISOString().split('T')[0].concat('T00:00:00')
  const endDate = new Date(now).toISOString().split('T')[0].concat('T00:00:00')
  // date strings of format yyyy-MM-ddThh:mm:ss (e.g. 2042-05-09T13:10:00)
  return {
    startDate,
    endDate,
    queryString: `start=${startDate}&end=${endDate}`
  }
}

export const useScheduleStore = defineStore('schedule', {
  state: () => ({
    locations: [],
    events: [],
    futureEvents: [],
    masterSchedulePastEvents: [],
    isEventsRequestSuccessful: true,
    eventForm: {},
    selectedEvent: null,
    fetchedDataProgramId: null,
    isEditingEvent: false,
    isDuplicatingEvent: false
  }),
  getters: {
    isEventFormEmpty: (state) => {
      const eventForm = state.eventForm

      for (const key of Object.keys(eventForm)) {
        // skip the eventType because it is always filled
        if ([key][0] === 'eventType') continue

        if (eventForm[key] instanceof Set && eventForm[key].size > 0) return false

        if (eventForm[key] !== null && eventForm[key] !== '' &&
        eventForm[key] !== undefined && eventForm[key].length) return false
      }
      return true
    }
  },
  actions: {
    resetEvents () {
      this.setScheduleEvents([])
      this.setFutureEvents([])
    },
    setSelectedPastEventById (eventId) {
      const selectedEvent = this.masterSchedulePastEvents.find(event => event.id === eventId)
      if (selectedEvent === undefined) {
        throw new Error(`Event [${eventId}] not found in masterSchedulePastEvents`)
      }
      this.setSelectedEvent(selectedEvent)
    },
    setSelectedEvent (event) {
      this.selectedEvent = event
    },
    setSelectedEventById (eventId) {
      const selectedEvent = this.events.find(event => event.id === eventId)
      if (selectedEvent === undefined) {
        throw new Error(`Event [${eventId}] not found in scheduleEvents`)
      }
      this.setSelectedEvent(selectedEvent)
    },
    async fetchScheduleEvents () {
      const meStore = useMeStore()
      if (meStore.isAdministrativeRole) return

      let requestPath = ''

      const proctorStore = useProctorStore()
      if (proctorStore.isViewingAsProctor) {
        requestPath = '/event/proctor'
      } else if (meStore.isResident) {
        requestPath = '/event/resident'
      } else {
        requestPath = '/event/faculty'
      }

      try {
        const response = await simseiApi.get(requestPath)
        const events = response.data
        this.setScheduleEvents(events)
        this.isEventsRequestSuccessful = true
      } catch (error) {
        this.isEventsRequestSuccessful = false
        throw error
      }
    },
    async fetchAllPastEvent () {
      const response = await simseiApi.get('/event/all-past-events')
      const events = response.data
      this.setMasterSchedulePastEvents(events)
    },
    // Loads a mix of all future event and events from a bit over a month in the past. Returns the startDate of an event
    // that is furthest in the past. If programId parameter is supplied, only events for that specific program are loaded.
    // otherwise, events for all programs are loaded.
    async fetchScheduleEventsAroundToday (programId) {
      // fetch past events for the next 38 days (current month + 7 extra days).
      // the + 7 days is to account for the cells that belong to the previous month in the month view
      const now = new Date()
      now.setHours(0, 0, 0, 0)
      const dateRanges = getDateRange(now.getTime(), 38)
      const pastEventsRequestUrl = programId
        ? `/event/program/${programId}/events-in-date-range?${dateRanges.queryString}`
        : `/event/events-in-date-range?${dateRanges.queryString}`
      const res = await simseiApi.get(pastEventsRequestUrl)
      const pastEvents = res.data

      const futureEventsRequestUrl = programId
        ? `/event/program/${programId}/future`
        : '/event/all-future-events'
      const response = await simseiApi.get(futureEventsRequestUrl)
      const events = response.data

      this.setFutureEvents(events)

      const allEvents = pastEvents.concat(events)
      this.setScheduleEvents(allEvents)
    },
    // Loads events between a specified date range. If programId parameter is supplied, then only events for that specific
    // program are loaded. Otherwise, events for all programs are loaded.
    async fetchScheduleEventsDaysBefore ({ programId, now, daysBefore }) {
      const dateRanges = getDateRange(now, daysBefore)
      const requestUrl = programId
        ? `/event/program/${programId}/events-in-date-range?${dateRanges.queryString}`
        : `/event/events-in-date-range?${dateRanges.queryString}`
      const res = await simseiApi.get(requestUrl)

      this.prependEventsLessThanMinDate({ events: res.data })
    },
    prependEventsLessThanMinDate ({ events }) {
      const oldestEvent = this.events[0]
      const minDateSeen = this.events.length > 0
        ? new Date(oldestEvent.startDate).getTime()
        : Number.MAX_SAFE_INTEGER
      // only add events that have not been seen (older than those already loaded)
      const filteredEvents = events.filter(event => {
        const start = new Date(event.startDate).getTime()
        return start < minDateSeen
      })

      this.setScheduleEvents(filteredEvents.concat(this.events))
    },
    async fetchFullEventDetails (eventId) {
      const response = await simseiApi.get(`/event/details/${eventId}`)
      const event = response.data
      return event
    },
    async fetchEventLocations (programId) {
      const resp = await simseiApi.get(`/event/program/${programId}/locations`)
      this.locations = resp.data
    },
    async submitEventForm () {
      const parsedEvent = this.parseEvent(this.eventForm)

      const form = new FormData()
      form.append('event', new Blob([JSON.stringify(parsedEvent)], { type: 'application/json' }))
      this.eventForm.attachments.forEach(file => {
        form.append('attachments', file)
      })

      const response = await simseiApi.post('/event', form, {
        headers: { 'Content-Type': `multipart/form-data; boundary=WebKitFormBoundary${randomAlphanumericString()}` }
      })

      this.addScheduleEvent(response.data)
      this.updateLocations(response.data.location)
      return response
    },
    async updateEvent () {
      const parsedEvent = this.parseEvent(this.eventForm)

      const form = new FormData()
      form.append('event', new Blob([JSON.stringify(parsedEvent)], { type: 'application/json' }))
      this.eventForm.attachments.forEach(file => {
        form.append('attachments', file)
      })

      const response = await simseiApi.patch('/event', form, {
        headers: { 'Content-Type': `multipart/form-data; boundary=WebKitFormBoundary${randomAlphanumericString()}` }
      })

      this.addScheduleEvent(response.data)
      this.updateLocations(response.data.location)
      return response
    },
    // Ensure that resident events aren't added to the dashboard while viewing as a proctor, and proctor
    // events aren't added to the dashboard while viewing as a resident. Also ensures that faculty events
    // are passed through, since they don't have the isViewingAsProctor flag.
    addScheduleEventFromWebsocket (event) {
      const meStore = useMeStore()
      const proctorStore = useProctorStore()
      const proctor = event.proctorAttendance.find(proctor => proctor.id === meStore.id)
      // If the user is proctoring this event and the user is in proctor mode, add the event.
      // If the user is not proctoring the event and the user is not in proctor mode, add the event.
      // If the user is faculty they will be in the proctor list, so this condition will be met.
      if (!!proctor === (proctorStore.isViewingAsProctor || meStore.isFaculty)) {
        this.addScheduleEvent(event)
        this.updateLocations(event.location)
      }
    },
    async deleteProgramEvent (eventId) {
      await simseiApi.delete('/event/' + eventId)
      this.deleteScheduleEvent(eventId)
    },
    initializeEventForm (type) {
      this.resetEventForm()
      this.setEventType(type)
    },
    updateSelectedActivites (activity) {
      if (this.eventForm.activities.some((selectActivity) => selectActivity.id === activity.id)) {
        this.removeSelectedActivity(activity)
      } else {
        this.addSelectedActivity(activity)
      }
    },
    async copySelectedEventToEventForm (isEditing) {
      const adminManageUsersStore = useAdminManageUsersStore()
      const selectedEvent = this.selectedEvent
      await this.fetchEventCreateModalData(selectedEvent.program.id)
      this.initializeEventForm(selectedEvent.type.eventType)

      // Set Program Id
      this.eventForm.programId = selectedEvent.program.id

      // Set Time and Event Id if Editing Event
      if (isEditing) {
        const startDateObj = parseDateAndTimeForProgram(selectedEvent.startDate)
        const endDateObj = parseDateAndTimeForProgram(selectedEvent.endDate)
        this.setEventFormStartDate(startDateObj.date)
        this.setEventFormStartTime(startDateObj.time)
        this.setEventFormEndTime(endDateObj.time)
        this.setEventId(selectedEvent.id)
      }

      // Set Title
      this.setFormTitle(selectedEvent.title)

      // Set Location
      this.setEventFormLocation(selectedEvent.location.name)

      // Set User Groups and Users
      const userGroupIdSet = new Set(selectedEvent.groupAttendance.map(userGroup => userGroup.id))
      const userIdSet = new Set(selectedEvent.residentAttendance.map(user => user.id))
      adminManageUsersStore.adminUnexpiredGroups.forEach(userGroup => {
        if (userGroupIdSet.has(userGroup.id)) {
          this.addEventFormUserGroupAttendee(userGroup)
        }

        if (userGroup.users) {
          userGroup.users.forEach(user => {
            if (userIdSet.has(user.id)) {
              this.addEventFormResidentAttendee(user)
              userIdSet.delete(user.id)
            }
          })
        }
      })

      // Set Proctors and Faculty
      const proctorIdSet = new Set(selectedEvent.proctorAttendance.map(proctor => proctor.id))
      adminManageUsersStore.programFaculty.forEach(eventProctor => {
        if (proctorIdSet.has(eventProctor.id) && (
          eventProctor.role === userRoles.FACULTY ||
          eventProctor.role === userRoles.PROG_DIRECTOR
        )) {
          this.addEventFormFacultyAttendee(eventProctor)
        }
      })
      adminManageUsersStore.programProctors.forEach(eventProctor => {
        if (proctorIdSet.has(eventProctor.id)) {
          this.addEventFormProctorAttendee(eventProctor)
        }
      })

      // Set Internal Users
      const interalIdSet = new Set(selectedEvent.internalAttendance.map(interal => interal.id))
      const userStore = useUserStore()
      userStore.officialInternalUsers.forEach(internal => {
        if (interalIdSet.has(internal.id) && internal.role === userRoles.SIMSEI_IMPLEMENTATION_MANAGER) {
          this.addEventFormSimAttendee(internal)
        }

        if (interalIdSet.has(internal.id) && internal.role === userRoles.FIELD_TEAM_MEMBER) {
          this.addEventFormFtmAttendee(internal)
        }
      })

      // Set Notes if exists
      if (selectedEvent.notes) {
        this.setEventFormNotes(selectedEvent.notes)
      }

      // Set Activities
      if (selectedEvent.type.eventType === eventTypes.LAB) {
        const activityIdMap = new Map(selectedEvent.activities.map(activity =>
          [activity.activity.id, activity.activityType]
        ))
        const { nonDeprecatedActivities } = useAdminStore()
        nonDeprecatedActivities.forEach(activity => {
          if (activityIdMap.has(activity.id)) {
            activity.activityType = activityIdMap.get(activity.id)
            this.addSelectedActivity(activity)
          }
        })
      }

      // Set Attachments
      this.setEventFormAttachments(selectedEvent.attachments)
    },
    async fetchEventCreateModalData (programId) {
      if (this.areUsersFetched && programId === this.fetchedDataProgramId) return
      const { fetchInternalUsers } = useUserStore()
      const { getProgramDetails, fetchNonDeprecatedActivities } = useAdminStore()
      const { fetchProgramFaculty, fetchProgramProctors, fetchUserGroupUsers } = useAdminManageUsersStore()

      await Promise.all([
        getProgramDetails(programId),
        fetchProgramFaculty(programId),
        fetchProgramProctors(programId),
        fetchInternalUsers(),
        this.fetchEventLocations(programId),
        fetchNonDeprecatedActivities(),
        fetchUserGroupUsers(programId)
      ])
      this.fetchedDataProgramId = programId
    },
    setScheduleEvents (events) {
      events.forEach(event => {
        event.startDate = convertToUtc(event.startDate)
        event.endDate = convertToUtc(event.endDate)
      })
      this.events = events
    },
    setFutureEvents (events) {
      events.forEach(event => {
        event.startDate = convertToUtc(event.startDate)
        event.endDate = convertToUtc(event.endDate)
      })
      this.futureEvents = events
    },
    setMasterSchedulePastEvents (events) {
      events.forEach(event => {
        event.startDate = convertToUtc(event.startDate)
        event.endDate = convertToUtc(event.endDate)
      })
      this.masterSchedulePastEvents = events
    },
    addScheduleEvent (event) {
      event.startDate = convertToUtc(event.startDate)
      event.endDate = convertToUtc(event.endDate)

      let eventIndex = this.events.findIndex(stateEvent => stateEvent.id === event.id)
      if (eventIndex >= 0) this.events.splice(eventIndex, 1)

      eventIndex = binarySearchEvent(this.events, event)
      this.events.splice(eventIndex, 0, event)

      let futureIndex = this.futureEvents.findIndex(stateEvent => stateEvent.id === event.id)
      if (futureIndex >= 0) this.futureEvents.splice(futureIndex, 1)

      futureIndex = binarySearchEvent(this.futureEvents, event)
      this.futureEvents.splice(futureIndex, 0, event)
    },
    deleteScheduleEvent (eventId) {
      const eventIndex = this.events.findIndex(event => event.id === eventId)
      if (eventIndex >= 0) this.events.splice(eventIndex, 1)

      const futureIndex = this.futureEvents.findIndex(event => event.id === eventId)
      if (futureIndex >= 0) this.futureEvents.splice(futureIndex, 1)

      const pastIndex = this.masterSchedulePastEvents.findIndex(event => event.id === eventId)
      if (pastIndex >= 0) this.masterSchedulePastEvents.splice(pastIndex, 1)
    },
    updateLocations (location) {
      if (!this.locations.find(loc => loc.name === location.name)) {
        this.locations.push(location)
        this.locations.sort()
      }
    },
    updateSelectedActivityType ({ activity, activityType }) {
      this.eventForm.activities[this.eventForm.activities.indexOf(activity)].activityType = activityType
    },
    resetEventForm () {
      const { resetNonDeprecatedActivities } = useAdminStore()
      this.eventForm = getEventFormState()
      resetNonDeprecatedActivities()
    },
    clearSelectedEvent () {
      this.selectedEvent = null
    },
    removeSelectedActivity (activity) {
      this.eventForm.activities.splice(this.eventForm.activities.indexOf(activity), 1)
      activity.activityType = null
    },
    addSelectedActivity (activity) {
      this.eventForm.activities.push(activity)
    },
    setIsDuplicateEvent (isDuplicating) {
      this.isDuplicatingEvent = isDuplicating
    },
    setIsEditingEvent (isEditing) {
      this.isEditingEvent = isEditing
    },
    setEventType (eventType) {
      this.eventForm.eventType = eventType
    },
    setFormTitle (title) {
      this.eventForm.title = title
    },
    setEventFormLocation (location) {
      this.eventForm.location = location
    },
    setEventFormStartDate (date) {
      this.eventForm.startDate = date
    },
    setEventFormStartTime (startTime) {
      this.eventForm.startTime = startTime
    },
    setEventFormEndTime (endTime) {
      this.eventForm.endTime = endTime
    },
    setEventFormNotes (notes) {
      this.eventForm.notes = notes
    },
    setEventFormAttachments (attachments) {
      this.eventForm.attachments = attachments
    },
    setEventFormProgramId (programId) {
      this.eventForm.programId = programId
    },
    setEventFormFacultyAttendees (facultyAttendees) {
      this.eventForm.facultyAttendees = facultyAttendees
    },
    addEventFormFacultyAttendee (facultyAttendee) {
      this.eventForm.facultyAttendees.push(facultyAttendee)
    },
    setEventFormProctorAttendees (proctorAttendees) {
      this.eventForm.proctorAttendees = proctorAttendees
    },
    addEventFormProctorAttendee (proctorAttendee) {
      this.eventForm.proctorAttendees.push(proctorAttendee)
    },
    setEventFormSimAttendees (simAttendees) {
      this.eventForm.simAttendees = simAttendees
    },
    addEventFormSimAttendee (simAttendee) {
      this.eventForm.simAttendees.push(simAttendee)
    },
    setEventFormUserGroupAttendees (userGroupAttendees) {
      this.eventForm.userGroupAttendees = userGroupAttendees
    },
    addEventFormUserGroupAttendee (userGroupAttendee) {
      this.eventForm.userGroupAttendees.add(userGroupAttendee)
    },
    setEventFormResidentAttendees (residentAttendees) {
      this.eventForm.residentAttendees = residentAttendees
    },
    addEventFormResidentAttendee (residentAttendee) {
      this.eventForm.residentAttendees.add(residentAttendee)
    },
    setEventFormFtmAttendees (ftmAttendees) {
      this.eventForm.ftmAttendees = ftmAttendees
    },
    addEventFormFtmAttendee (ftmAttendee) {
      this.eventForm.ftmAttendees.push(ftmAttendee)
    },
    setEventId (eventId) {
      this.eventForm.id = eventId
    },
    parseEventLocation (event) {
      const location = this.locations
        .find(location => location.name === event.location) || { name: event.location }
      return {
        ...location,
        name: location.name.trim()
      }
    },
    parseEvent (event) {
      const { adminSelectedProgram } = useAdminStore()
      return {
        id: event.id,
        title: event.title,
        startDate: parseLocaleDate(event.startDate, event.startTime),
        endDate: parseLocaleDate(event.startDate, event.endTime),
        location: this.parseEventLocation(event),
        notes: event.notes.trim(),
        type: { eventType: event.eventType },
        program: { id: event.programId || adminSelectedProgram.id },
        groupAttendance: [...event.userGroupAttendees],
        residentAttendance: [...event.residentAttendees],
        proctorAttendance: [...event.facultyAttendees, ...event.proctorAttendees],
        internalAttendance: [...event.simAttendees, ...event.ftmAttendees],
        activities: event.eventType === eventTypes.LAB ? formatActivity(event.activities) : [],
        attachments: [...event.attachments.filter(attachment => attachment.filePath)]
      }
    }
  }
})
