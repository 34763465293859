import { defineStore } from 'pinia'
import { workshopApi } from '@/workshop/workshop-api'

const getDefaultState = () => {
  return {
    profilePhoto: {
      fileName: '',
      filePath: ''
    },
    croppedBase64Image: '',
    baseFileName: ''
  }
}

export const useParticipantProfileDetailsStore = defineStore('participantProfileDetails', {
  persist: { storage: sessionStorage },
  state: () => getDefaultState(),
  getters: {
    profilePhotoPath (state) {
      return state.profilePhoto.filePath || ''
    }
  },
  actions: {
    async getProfilePhoto (userId) {
      const res = await workshopApi.get('/workshop-participant-profile/profile-picture')
      this.profilePhoto = res.data
    },
    resetProfilePhoto () {
      this.profilePhoto = getDefaultState().profilePhoto
    },
    setCroppedImage ({ base64Image, fileName }) {
      this.croppedBase64Image = base64Image
      this.baseFileName = fileName
    },
    resetCroppedImage () {
      this.croppedBase64Image = ''
      this.baseFileName = ''
    }
  }
})
