<template>
  <span
    v-if="showCharsLeft"
    class="characters-left"
  >
    {{ calculateCharactersLeft }} characters left
  </span>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      required: true
    },
    maxlength: {
      type: Number,
      required: true
    },
    triggerValue: {
      type: Number,
      required: false,
      default: 50
    }
  },
  data () {
    return {
      charsLeft: null
    }
  },
  computed: {
    showCharsLeft () {
      if (this.modelValue.length >= this.maxlength - this.triggerValue && this.modelValue.length <= this.maxlength) {
        return true
      }

      return false
    },
    calculateCharactersLeft () {
      return this.maxlength - this.modelValue.length
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/components/inputs/input.scss";

.characters-left {
  font-family: "Open Sans Semi-Bold";
  font-size: 12px;
  color: $supporting-danger-red;
}
</style>
