import { defineStore } from 'pinia'
import { useMeStore } from './me'
import { useToastNotificationStore } from '@/stores/toast-notification'
import { useWorkshopProcedureStore } from '@/workshop/stores/workshop-procedure'
import { useParticipantProfileDetailsStore } from '@/workshop/stores/participant/participant-profile-details'
import { useAdminUserStore } from '@/workshop/stores/admin/admin-user'
import { useWorkshopFormStore } from '@/workshop/stores/admin/workshop-form'
import { useWorkshopHistoryStore } from '@/workshop/stores/admin/workshop-history'
import { useWorkshopAttendeeMgmtStore } from '@/workshop/stores/admin/workshop-attendee-mgmt'
import { useWorkshopCourseMgmtStore } from '@/workshop/stores/admin/workshop-course-mgmt'
import { useUserProfileFormStore } from '@/workshop/stores/dashboard/workshop-user-profile-form'
import { useParticipantUserDetailsStore } from '@/workshop/stores/admin/participant-user-details'
import { useWorkshopUserMgmtFormStore } from '@/workshop/stores/admin/workshop-user-mgmt-form'

export const useAuthStore = defineStore('workshopAuth', {
  persist: { storage: sessionStorage },
  state: () => ({
    tokenInfo: {
      accessToken: '',
      refreshToken: '',
      accessTokenDuration: 0,
      accessTokenRetrievedDate: new Date()
    }
  }),
  getters: {
    isAuthenticated: state => !!state.tokenInfo.refreshToken
  },
  actions: {
    async login (authToken) {
      this.saveNewAuthToken(authToken)
      const { getMe } = useMeStore()
      await getMe()
    },
    saveNewAuthToken (authToken) {
      /* eslint-disable camelcase */
      const { access_token, refresh_token, expires_in } = authToken

      const newTokenInfo = {
        accessToken: access_token,
        refreshToken: refresh_token,
        accessTokenDuration: expires_in,
        accessTokenRetrievedDate: new Date()
      }
      /* eslint-enable camelcase */

      this.tokenInfo = newTokenInfo
    },
    clearState () {
      const meStore = useMeStore()
      const toastNotifStore = useToastNotificationStore()
      const procedureStore = useWorkshopProcedureStore()
      const participantProfileDetailsStore = useParticipantProfileDetailsStore()
      const adminUserStore = useAdminUserStore()
      const workshopFormStore = useWorkshopFormStore()
      const workshopHistoryStore = useWorkshopHistoryStore()
      const attendeeMgmtStore = useWorkshopAttendeeMgmtStore()
      const courseMgmtStore = useWorkshopCourseMgmtStore()
      const userProfileFormStore = useUserProfileFormStore()
      const participantUserDetailsStore = useParticipantUserDetailsStore()
      const workshopUserMgmtFormStore = useWorkshopUserMgmtFormStore()

      toastNotifStore.$reset()
      meStore.$reset()
      participantProfileDetailsStore.$reset()
      procedureStore.$reset()
      adminUserStore.$reset()
      workshopFormStore.$reset()
      workshopHistoryStore.$reset()
      attendeeMgmtStore.$reset()
      courseMgmtStore.$reset()
      userProfileFormStore.$reset()
      participantUserDetailsStore.$reset()
      workshopUserMgmtFormStore.$reset()
      this.$reset()
    }
  }
})
