export function binarySearch (list, item, compare, start, end) {
  if (!start) start = 0
  if (end === undefined) end = list.length - 1

  if (start === end) {
    if (compare(list[start], item) > 0) return start
    else return start + 1
  }
  if (start > end) return start

  const mid = Math.floor((start + end) / 2)
  if (compare(list[mid], item) < 0) {
    return binarySearch(list, item, compare, mid + 1, end)
  } else if (compare(list[mid], item) > 0) {
    return binarySearch(list, item, compare, start, mid - 1)
  } else {
    return mid
  }
}
