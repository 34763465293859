import { randomString } from '@/utils/string-util'

/**
 * Singleton class that stores messages for a websocket connections.
 */
class MessageBuffer {
  constructor () {
    if (MessageBuffer.instance) {
      return MessageBuffer.instance
    }
    MessageBuffer.instance = this

    this.buffer = {}
  }

  put (streamId, messageId, destination, messageBody, headers) {
    if (!this.buffer[streamId]) {
      this.buffer[streamId] = {}
    }
    this.buffer[streamId][messageId] = {
      destination,
      messageBody,
      headers
    }
  }

  ack (streamId, messageId) {
    if (!this.buffer[streamId] || !this.buffer[streamId][messageId]) {
      return
    }

    delete this.buffer[streamId][messageId]
  }

  getMissingMessages (streamId) {
    if (!this.buffer[streamId]) {
      return []
    }

    return Object.keys(this.buffer[streamId]).map((messageId) => {
      return {
        messageId,
        content: this.buffer[streamId][messageId]
      }
    })
  }

  resetBuffer () {
    this.buffer = {}
  }
}

function _getBuffer () {
  return new MessageBuffer()
}

function _buildMessageId () {
  // convert to base 64 to avoid special characters
  return Buffer.from(randomString()).toString('base64')
}

function _onSend (target, streamType, destination, body, headers) {
  const messageId = headers && 'receipt' in headers ? headers['receipt'] : _buildMessageId()

  _getBuffer().put(streamType, messageId, destination, body, headers)

  // invoke the original send method
  target.send(destination, body, {
    ...headers,
    'receipt': messageId
  })
}

function _onReceipt (target, streamType, frame) {
  const messageId = frame.headers['receipt-id']

  _getBuffer().ack(streamType, messageId)

  if (target.onreceipt) {
    target.onreceipt(frame)
  }
}

/**
 * Creates a proxy object that will buffer message to recover from websocket disconnects.
 */
export function buildBufferProxy (wsClient, streamType) {
  return new Proxy(wsClient, {
    get (target, prop) {
      if (prop === 'send') {
        return function (destination, body, headers) {
          _onSend(target, streamType, destination, body, headers)
        }
      }

      if (prop === 'onreceipt') {
        return function (frame) {
          _onReceipt(target, streamType, frame)
        }
      }

      // default behavior
      return target[prop]
    }
  })
}

export function retransmitMissingMessages (wsClient, streamId) {
  const missingMessages = _getBuffer().getMissingMessages(streamId)
  missingMessages.forEach((messageInfo) => {
    const message = messageInfo.content
    wsClient.send(message.destination, message.messageBody, {
      ...message.headers,
      'receipt': messageInfo.messageId
    })
  })

  return missingMessages.length
}

export function getBufferSize (streamId) {
  return _getBuffer().getMissingMessages(streamId).length
}

export function resetBuffer () {
  const messageBuffer = _getBuffer()
  messageBuffer.resetBuffer()
}
