<template>
  <div>
    <input
      v-model="value"
      type="date"
      v-bind="$attrs"
      class="base-input-date"
      :data-cy="dataCy"
      @input="validate"
    >
    <InputErrorBucket v-if="hasValidationRules" :message="errorMessage" />
  </div>
</template>

<script>
import InputErrorBucket from './components/InputErrorBucket.vue'
import { useInputValidation } from './composables/useInputValidation'

export default {
  components: { InputErrorBucket },
  props: {
    modelValue: {
      type: [String, Date],
      required: false
    },
    rules: {
      type: Function,
      required: false
    },
    dataCy: {
      type: String,
      required: false
    }
  },
  emits: ['update:modelValue'],
  setup (props) {
    const {
      errorMessage,
      hasValidationRules,
      isValid,
      setAllowValidation,
      validate,
      resetValidation,
      reset
    } = useInputValidation(props)
    return { errorMessage, hasValidationRules, isValid, setAllowValidation, validate, resetValidation, reset }
  },
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/components/inputs/input.scss";

.base-input-date {
  @include form-control;

  max-width: 220px;
}
</style>
