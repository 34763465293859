import { defineStore } from 'pinia'
import { simseiApi } from '@/residency/app-props'
import { videoTypeEnums } from '@/residency/views/video/video-enums'

export const useSharedVideoStore = defineStore('sharedVideo', {
  state: () => ({
    allSharedVideos: [],
    sentByFaculty: [],
    savedVideos: [],
    currentSharedLibraryRequest: null
  }),
  getters: {
    sharedSimulationVideos: state => {
      return state.allSharedVideos.filter(video => {
        return videoTypeEnums[video.videoType] === videoTypeEnums.SIMULATION
      })
    },
    sharedSurgicalVideos: state => {
      return state.allSharedVideos.filter(video => {
        return videoTypeEnums[video.videoType] === videoTypeEnums.SURGICAL
      })
    },
    sharedOtherVideos: state => {
      return state.allSharedVideos.filter(video => {
        return videoTypeEnums[video.videoType] === videoTypeEnums.OTHER
      })
    },
    mySharedVideos: state => {
      return state.allSharedVideos.filter(video => video.isMyVideo)
    }
  },
  actions: {
    async addToSharedVideoLibrary (payload) {
      await simseiApi.post('/shared-library', payload)
    },
    async fetchSavedVideos () {
      await this.fetchSentByFaculty()

      const filteredVideos = this.allSharedVideos.filter(video => {
        return video.isFavorited
      })
      this.savedVideos = filteredVideos

      return filteredVideos
    },
    async fetchAllSharedVideos () {
      if (this.allSharedVideos.length > 0) {
        return this.allSharedVideos
      } else {
        const res = await simseiApi.get('/shared-library/videos/all')
        this.allSharedVideos = res.data
        return res.data
      }
    },
    async fetchSentByFaculty () {
      if (this.sentByFaculty.length > 0) {
        return this.sentByFaculty
      } else {
        const res = await simseiApi.get('/shared-library/videos/recommended-shared')
        this.sentByFaculty = res.data
        return res.data
      }
    },
    async checkForSharedLibraryRequest (asmtId) {
      const res = await simseiApi.get('/shared-library-request/' + asmtId)
      this.currentSharedLibraryRequest = res.data
    },
    async acceptSharedLibraryRequest (requestId) {
      await simseiApi.patch('/shared-library-request/accept/' + requestId)
    },
    async saveVideo (sharedVideoId) {
      const favoritedVideo = await simseiApi.post('/favorite-video/' + sharedVideoId)
      this.saveFavoriteVideo({ sharedVideoId, favoriteId: favoritedVideo.data.id })
      return favoritedVideo.data.id
    },
    async unsaveVideo (favoriteId) {
      await simseiApi.delete('/favorite-video/' + favoriteId)
      this.unsaveFavoriteVideo(favoriteId)
    },
    async removeVideoFromSharedLibrary (sharedVideoId) {
      await simseiApi.delete('/shared-library/' + sharedVideoId)

      const indexToRemove = this.allSharedVideos.findIndex(video => video.sharedVideoId === sharedVideoId)
      this.allSharedVideos.splice(indexToRemove, 1)
    },
    saveFavoriteVideo ({ sharedVideoId, favoriteId }) {
      const video = this.allSharedVideos.find(video => video.sharedVideoId === sharedVideoId)
      const sentByFaculty = this.sentByFaculty.find(video => video.sharedVideoId === sharedVideoId)

      if (video) {
        video.favoriteId = favoriteId
        video.isFavorited = true
        this.savedVideos.push(video)
      }

      if (sentByFaculty) {
        sentByFaculty.favoriteId = favoriteId
        sentByFaculty.isFavorited = true
      }
    },
    unsaveFavoriteVideo (favoriteId) {
      const unsavedVideo = this.allSharedVideos.find(video => video.favoriteId === favoriteId)
      const sentByFaculty = this.sentByFaculty.find(video => video.videoId === unsavedVideo.videoId)

      if (sentByFaculty) {
        sentByFaculty.favoriteId = null
        sentByFaculty.isFavorited = false
      }

      if (unsavedVideo) {
        unsavedVideo.favoriteId = null
        unsavedVideo.isFavorited = false
        this.savedVideos = this.allSharedVideos.filter(video => video.isFavorited)
      }
    }
  }
})
