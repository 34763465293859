<template>
  <BaseButtonIcon
    icon="x.svg"
    data-cy="event-form-close-button"
    @click="$emit('close')"
  />
</template>

<script>
export default {
  emits: ['close']
}
</script>

<style lang="scss" scoped>
</style>
