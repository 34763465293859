<template>
  <div :class="classes" @click="updateValue">
    <div :class="['radio-button', { 'radio-button-checked': isSelected }]">
    </div>
    <input
      type="radio"
      :class="['base-input-radio',{ 'base-input-radio-checked': isSelected }]"
      :value="value"
      :name="radioGroupName"
      :checked="isSelected"
      :disabled="isRadioGroupDisabled"
    />
    <label class="base-input-radio-label" :class="$attrs.class" :style="cssLabelStyle">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  inject: [
    'radioGroupValue',
    'radioGroupName',
    'setRadioSelected',
    'isRadioGroupInvalid',
    'isRadioGroupDisabled'
  ],
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number, Object, Boolean],
      required: true
    },
    fontSize: {
      type: String,
      default: '16px'
    }
  },
  computed: {
    isSelected () {
      return this.radioGroupValue === this.value
    },
    classes () {
      return {
        'base-input-radio-container': true,
        'disabled': this.isRadioGroupDisabled,
        'base-input-radio-error': this.isRadioGroupInvalid
      }
    },
    cssLabelStyle () {
      return {
        fontSize: this.fontSize
      }
    }
  },
  methods: {
    updateValue () {
      if (!this.isRadioGroupDisabled) {
        this.setRadioSelected(this.value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$radio-disabled-color: rgba(0, 0, 0, 0.5);
$radio-button-bg: rgba(0, 0, 0, 0.2);
$radio-button-bg-checked: rgba(0, 121, 131, 0.2);

.radio-button, .base-input-radio, .base-input-radio-label {
  cursor: pointer;
}

.radio-button > .disabled, .base-input-radio > .disabled, .base-input-radio-label > .disabled {
  cursor: default;
}

.base-input-radio-container {
  position: relative;
  overflow: visible;
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.radio-button {
  position: absolute;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  left: -7px;
  transform: scale(1.2);
  transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

  &:hover {
    background-color: $radio-button-bg;
  }
}

.radio-button-checked:hover {
  background-color: $radio-button-bg-checked; // primary-digital-teal-default
}

.base-input-radio {
  margin-right: 12px;
  appearance: none;
  min-width: 20px;
  min-height: 20px;
  border: 2px solid $radio-disabled-color;
  border-radius: 50%;
  display: grid;
  place-content: center;

  &::before {
    content: "";
    width: 12px;
    height: 12px;
    transform: scale(0);
    border-radius: 50%;
    box-shadow: inset 1em 1em $primary-digital-teal-default;
    pointer-events: none;
  }

  &[disabled]::before {
    box-shadow: inset 1em 1em $radio-disabled-color;
  }
}

.base-input-radio-checked {
  border: 2px solid $primary-digital-teal-default;

  &[disabled] {
    border: 2px solid $radio-disabled-color;
  }

  &::before {
    transform: scale(1);
  }
}

.base-input-radio-label {
  line-height: 24px;
  color: $neutral-typography-dark;
}

.base-input-radio-error .base-input-radio {
  border: 2px solid $supporting-danger-red;
}

.base-input-radio-error .base-input-radio-label {
  color: $supporting-danger-red;
}
</style>
