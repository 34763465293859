const listeners = {
  STREAM_FAILED: '/user/topic/video/stream-error',
  CDN_FAILED: '/user/topic/video/cdn-error',
  CDN_DONE: '/user/topic/video/cdn-done',
  VIDEO_NEW_ASMT: '/user/topic/video/new-asmt',
  PROCESSING_DONE: '/user/topic/video/processing-done'
}

export default {
  listeners
}
