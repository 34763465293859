<template>
  <div class="base-card-title">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BaseCardTitle'
}
</script>

<style lang="scss" scoped>
.base-card-title {
  padding: 0.5rem 1rem;
}
</style>
