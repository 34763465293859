import { defineStore } from 'pinia'
import { workshopApi } from '@/workshop/workshop-api.js'
import { workshopBaseSpecialtyArray, workshopSpecialtyEnums } from '@/workshop/consts/attendee-specialty-options.js'

export const useUserProfileFormStore = defineStore('workshopUserProfileForm', {
  state: () => ({
    userProfileForm: {
      id: null,
      email: null,
      firstName: null,
      lastName: null,
      primaryPhone: null,
      hospital: {
        hospitalAffiliation: null,
        streetAddress: null,
        secondaryAddress: null,
        city: null,
        state: null,
        zipCode: null,
        country: null,
        otherCountry: null
      },
      currentMedicalCredentialStatus: null,
      cellPhone: null,
      otherSpecialty: null,
      specialties: []
    }
  }),
  actions: {
    async fetchFacultyProfileForm () {
      const res = await workshopApi.get('/faculty/profile/fetch')
      this.setProfileInfo(res.data)
    },
    async fetchAttendeeProfileForm () {
      const res = await workshopApi.get('/attendee/profile/fetch')
      this.setProfileInfo(res.data)
    },
    async updateAttendeeProfileForm () {
      const payload = this.getUpdatePayload()
      await workshopApi.put('/attendee/profile/update', payload)
    },
    async updateFacultyProfileForm () {
      const payload = this.getUpdatePayload()
      await workshopApi.put('/faculty/profile/update', payload)
    },
    setProfileInfo (profileInfo) {
      this.$patch({
        userProfileForm: {
          ...this.userProfileForm,
          ...profileInfo
        }
      })

      if (profileInfo.hospital.country !== 'USA') {
        this.userProfileForm.hospital.country = 'Other'
        this.userProfileForm.hospital.otherCountry = profileInfo.hospital.country
      }

      if (profileInfo.specialties) {
        const otherSpecialty = profileInfo.specialties.find(
          specialty => !workshopBaseSpecialtyArray.includes(specialty)
        )

        if (otherSpecialty) {
          this.userProfileForm.specialties.push(workshopSpecialtyEnums.OTHER)
          this.userProfileForm.otherSpecialty = otherSpecialty
        }
      }
    },
    getUpdatePayload () {
      if (this.userProfileForm.hospital.country === 'Other') {
        this.userProfileForm.hospital.country = this.userProfileForm.hospital.otherCountry
      }
      return {
        ...this.userProfileForm,
        specialties: this.userProfileForm.specialties.filter(specialty => specialty !== workshopSpecialtyEnums.OTHER)
      }
    },
    setCurrentStatus (status) {
      this.userProfileForm.currentMedicalCredentialStatus = status
    },
    setFirstName (name) {
      this.userProfileForm.firstName = name
    },
    setLastName (name) {
      this.userProfileForm.lastName = name
    },
    setEmail (email) {
      this.userProfileForm.email = email
    },
    setPrimaryPhoneNumber (phone) {
      this.userProfileForm.primaryPhone = phone
    },
    setCellPhoneNumber (phone) {
      this.userProfileForm.cellPhone = phone
    },
    setSpecialties (specialties) {
      this.userProfileForm.specialties = specialties
    },
    setOtherSpecialty (specialty) {
      this.userProfileForm.otherSpecialty = specialty
    },
    setHospitalAffiliation (affiliation) {
      this.userProfileForm.hospital.hospitalAffiliation = affiliation
    },
    setHospitalStreetAddress (address) {
      this.userProfileForm.hospital.streetAddress = address
    },
    setHospitalSecondaryAddress (address) {
      this.userProfileForm.hospital.secondaryAddress = address
    },
    setHospitalCity (city) {
      this.userProfileForm.hospital.city = city
    },
    setHospitalZipCode (zip) {
      this.userProfileForm.hospital.zipCode = zip
    },
    setHospitalState (state) {
      this.userProfileForm.hospital.state = state
    },
    setHospitalCountry (country) {
      this.userProfileForm.hospital.country = country
    },
    setHospitalOtherCountry (country) {
      this.userProfileForm.hospital.otherCountry = country
    }
  }
})
