import { defineStore } from 'pinia'
import { simseiApi } from '@/residency/app-props'
import { systemSelectionTypes } from '@/residency/views/admin/admin-enum'

export const useAdminStore = defineStore('admin', {
  persist: { storage: sessionStorage },
  state: () => ({
    systems: [],
    activePrograms: [],
    inactivePrograms: [],
    adminSelectedProgram: null,
    dneSystemId: null,
    nonDeprecatedActivities: null
  }),
  actions: {
    async getSystemsData () {
      const response = await simseiApi.get('/system?projection=inlineSystemInfo')
      const systems = response.data._embedded.system
      this.systems = systems
      this.setActiveAndInactivePrograms()
      this.setDneSystemId(systems)
      return systems
    },
    async getSystemDetails (systemId) {
      const response = await simseiApi.get(`/system/${systemId}`, {
        params: {
          projection: 'inlineSystemInfo'
        }
      })
      return response.data
    },
    async fetchProgramDetailsForEdit (programId) {
      const program = await simseiApi.get(`/program/${programId}?projection=inlineProgramDetails`)
      const specialtyRes = await simseiApi.get(`/program/getSpecialty/${programId}`)
      const res = await simseiApi.get(`/program/program-director/${programId}`)
      const activeProgramDirectors = res.data.filter(direcor => !direcor.isExpired)
      return {
        programName: program.data.title,
        programAddresses: program.data.programAddresses,
        timeZone: program.data.timezoneOption.timezone,
        specialty: specialtyRes.data,
        programDirector: activeProgramDirectors,
        accountNumber: program.data.accountNumber,
        implementationUserId: program.data.implementationUserId,
        fieldTeamUserId: program.data.fieldTeamUserId
      }
    },
    async getProgramDetails (programId) {
      if (this.adminSelectedProgram?.id === Number(programId)) {
        return this.adminSelectedProgram
      }
      const response = await simseiApi.get(`/program/${programId}?projection=inlineProgramDetails`)
      this.adminSelectedProgram = response.data
      return response.data
    },
    async forceRefreshProgramDetails (programId) {
      const response = await simseiApi.get(`/program/${programId}?projection=inlineProgramDetails`)
      this.adminSelectedProgram = response.data
      return response.data
    },
    updateUser (user) {
      return simseiApi.patch('/user/' + user.id, user)
    },
    updateUserGroupCourse ({ groupCourseId, showOrHide, activityId }) {
      return simseiApi.patch(`/user-group-course/${groupCourseId}/${showOrHide}/${activityId}`)
    },
    async getUserGroups (programId) {
      const response = await simseiApi.get(`/user-group/program/${programId}`)
      return response.data
    },
    createSystem (system) {
      return simseiApi.post('/system', system)
    },
    updateInstitution (institution) {
      return simseiApi.patch(`/institution/${institution.id}`, institution)
    },
    async setActiveAndInactivePrograms () {
      const activePrograms = []
      const inactivePrograms = []
      const response = await simseiApi.get('/program?projection=inlineProgramDetails')
      const programs = response.data._embedded.program

      programs.forEach(program => {
        if (program.activeProgram) {
          activePrograms.push(program)
        } else {
          inactivePrograms.push(program)
        }
      })
      this.activePrograms = activePrograms
      this.inactivePrograms = inactivePrograms
    },
    addInstitution (payload) {
      return simseiApi.post('/institution', payload)
    },
    createProgram (payload) {
      return simseiApi.post(`/program?sendRegistrationEmail=${payload.sendRegistrationEmail}`, payload.program)
    },
    editProgram (program) {
      return simseiApi.post('/program/edit', program)
    },
    async deleteSystemCoord (coordinator) {
      return simseiApi.delete(`/user/${coordinator.id}`)
    },
    setDneSystemId (systems) {
      const dneSystem = systems.find(system => system.systemName === systemSelectionTypes.DNE)
      this.dneSystemId = dneSystem.id
    },
    async fetchNonDeprecatedActivities () {
      const activitiesRes = await simseiApi.get('activity/search/findAllByIsDeprecated', {
        params: {
          isDeprecated: false,
          projection: 'inlineActivityNameAndId'
        }
      })

      const activities = activitiesRes.data._embedded.activity
      activities.forEach((activity) => delete activity['_links'])
      activities.sort((a, b) => a.name.localeCompare(b.name))
      this.nonDeprecatedActivities = activities
    },
    resetNonDeprecatedActivities () {
      if (!this.nonDeprecatedActivities) return

      this.nonDeprecatedActivities.forEach((activity) => {
        activity.activityType = null
      })
    }
  }
})
