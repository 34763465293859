import { defineStore } from 'pinia'
import { workshopApi } from '@/workshop/workshop-api'

export const useWorkshopProcedureStore = defineStore('workshopProcedure', {
  persist: { storage: sessionStorage },
  state: () => ({
    procedures: []
  }),
  actions: {
    async fetchWorkshopProcedures () {
      if (this.procedures.length) return
      const res = await workshopApi.get('/workshop-procedure')
      const procedures = res.data._embedded.workshopProcedure.map(procedure => {
        return {
          id: procedure.id,
          text: procedure.name,
          shortText: procedure.shortText
        }
      })
      this.procedures = procedures
    }
  }
})
