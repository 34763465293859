/**
 * This file contains the debounce and throttle tools for rate limiting functionality.
 *
 * **NOTE**: To use these methods in conjunction with `window.addEventListener()` we *must*
 * follow a pattern where we declare the debounced method using:
 *
 * ```
 * this.myDebouncedCall = debounce(myFunction, time)
 * window.addEventListener('scroll', this.myDebouncedCall)
 * ```
 *
 * Putting `myDebouncedCall` into a `methods` hook in Vue will change the scope inside
 * these functions and fail to make the debounced call. For an example of usage, see
 * WhatsNextCard.vue.
 */

export const debounce = (callback, interval) => {
  let timeout, context, args

  const debounced = function () {
    context = this
    args = arguments
    clearTimeout(timeout)
    const functionCall = () => callback.apply(context, args)
    timeout = setTimeout(functionCall, interval)
  }

  debounced.flush = function () {
    clearTimeout(timeout)
    callback.apply(context, args)
  }

  return debounced
}

export const throttle = (callback, interval) => {
  let enableCall = true

  return function () {
    if (!enableCall) return

    enableCall = false
    callback.apply(this, arguments)
    setTimeout(() => { enableCall = true }, interval)
  }
}
