<template>
  <div>
    <div class="input-row">
      <input
        type="checkbox"
        :checked="isChecked"
        :id="id"
        :name="name"
        :autofocus="autofocus"
        :disabled="isDisabled"
        :required="required"
        :value="value"
        :class="checkboxCssClass"
        :style="{ 'min-height': size, 'min-width': size }"
        :data-cy="dataCy"
        @input="onValueChange"
      >
      <label
        :for="id"
        class="base-checkbox-label"
        :class="{ 'base-checkbox-label-disabled': isDisabled, 'base-checkbox-label-invalid': isInvalid }"
      >
        <slot></slot>
      </label>
    </div>
    <InputErrorBucket v-if="hasValidationRules" :message="errorMessage" class="error-msg"/>
  </div>
</template>

<script>
import InputErrorBucket from './components/InputErrorBucket.vue'
import { useInputValidation } from './composables/useInputValidation.js'

export default {
  components: { InputErrorBucket },
  inject: {
    // Default to null so that the checkbox can be used without a BaseCheckboxGroup
    'checkboxGroupValue': { default: null },
    'isCheckboxGroupInvalid': { default: null },
    'isCheckboxGroupDisabled': { default: null },
    'setCheckboxSelected': { default: null }
  },
  props: {
    id: {
      type: [String, Number],
      requird: false,
      default: 'base-checkbox'
    },
    modelValue: {
      required: false
    },
    value: {
      type: [String, Object, Boolean],
      required: false
    },
    rules: {
      type: Function,
      required: false
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: false,
      default: 'base-checkbox'
    },
    size: {
      type: String,
      required: false,
      default: '18px'
    },
    dataCy: {
      type: [String, Number],
      required: false
    }
  },
  emits: ['update:modelValue'],
  setup (props) {
    const {
      errorMessage,
      hasValidationRules,
      isValid,
      setAllowValidation,
      validate,
      resetValidation,
      reset
    } = useInputValidation(props)
    return { errorMessage, hasValidationRules, isValid, setAllowValidation, validate, resetValidation, reset }
  },
  computed: {
    checkboxCssClass () {
      return {
        'base-checkbox': true,
        'base-checkbox-disabled': this.isDisabled
      }
    },
    isChecked () {
      if (Array.isArray(this.inputValue)) {
        return this.inputValue.find(value => value === this.value)
      } else {
        return this.inputValue
      }
    },
    isInvalid () {
      return !!this.isCheckboxGroupInvalid
    },
    isDisabled () {
      return this.disabled || !!this.isCheckboxGroupDisabled
    },
    inputValue () {
      // If this checkbox is part of a BaseCheckboxGroup, the parent component will handle the value.
      // Otherwise, the checkbox will handle the value through this.modelValue
      return this.checkboxGroupValue
        ? this.checkboxGroupValue
        : this.modelValue
    }
  },
  methods: {
    onValueChange (event) {
      if (Array.isArray(this.inputValue)) {
        if (this.inputValue.find(value => value === this.value)) {
          this.setValue(this.inputValue.filter(value => value !== this.value))
        } else {
          this.setValue([...this.inputValue, this.value])
        }
      } else {
        this.setValue(event.target.checked)
      }
    },
    setValue (value) {
      this.resetValidation()
      if (this.checkboxGroupValue) {
        // Update through the BaseCheckboxGroup component
        this.setCheckboxSelected(value)
      } else {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/components/inputs/input.scss";

.input-row {
  display: flex;
  align-items: center;
}

.base-checkbox {
  @include base-checkbox;
}

.base-checkbox-label {
  @include base-checkbox-label;
}

.base-checkbox-label-disabled {
  color: $neutral-dark-grey;
}

.base-checkbox-label-invalid {
  @include base-checkbox-label-invalid;
}

.base-checkbox-disabled, .base-checkbox-label-disabled {
  cursor: default;
}

.error-msg {
  @include error-msg;
}
</style>
