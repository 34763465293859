import { defineStore } from 'pinia'
import videoFeedbackTypes from '@/residency/consts/video-feedback-types'
import { videoFilterEnums } from '@/residency/views/video/video-enums'

export const useVideoFeedbackStore = defineStore('videoFeedback', {
  persist: { storage: sessionStorage },
  state: () => ({
    /* Recommended Video Object
      {
        sharedVideo: { id: sharedVideoId },
        comment: 'This is a Lorem Ipsum comment',
        timeStamp: 3.33,
        videoLength: '777',
        thumbnail: null,
        assessor: name,
        creationDate: '07-07-7777'
        videoAsmtId: 'ABCDEFG-1234-4566-DHSH-3283728YDU'
      }
    */
    recommendedVideos: [],
    comments: [],
    selectedVideo: {},
    selectedVideoType: '',
    searchText: '',
    feedbackTimestamps: [],
    currentVideoAsmtId: null,
    videosCurrentTimestamp: {}
  }),
  actions: {
    addRecommendedVideo (recommendedVideo) {
      this.recommendedVideos.push(recommendedVideo)
      this.feedbackTimestamps.push({
        timeStamp: recommendedVideo.timeStamp,
        videoFeedbackType: videoFeedbackTypes.RECOMMENDATION,
        feedbackId: recommendedVideo.recommendedVideoId
      })
    },
    removeRecommendedVideo ({ recommendedVideoId: id }) {
      const index = this.recommendedVideos.findIndex(video => video.sharedVideo.id === id)
      const timestampIndex = this.feedbackTimestamps.findIndex(fb =>
        fb.videoFeedbackType === videoFeedbackTypes.RECOMMENDATION && fb.feedbackId === id)
      this.recommendedVideos.splice(index, 1)
      this.feedbackTimestamps.splice(timestampIndex, 1)
    },
    addComment (comment) {
      this.comments.push(comment)
      this.feedbackTimestamps.push({
        timeStamp: comment.timeStamp,
        videoFeedbackType: videoFeedbackTypes.COMMENT,
        duration: comment.duration || 0,
        feedbackId: comment.commentId
      })
    },
    setVideoFeedbackState ({ recommendedVideos, timestamps }) {
      this.recommendedVideos = recommendedVideos
      this.feedbackTimestamps = timestamps
    },
    setSelectedVideo (selectedVideo) {
      this.selectedVideo = selectedVideo
    },
    setSelectedVideoType (selectedVideoType) {
      this.selectedVideoType = selectedVideoType
    },
    setVideoCurrentTimestamp ({ id, currentTime }) {
      if (this.videosCurrentTimestamp.hasOwnProperty(id)) {
        this.videosCurrentTimestamp[id].currentTime = currentTime
      } else {
        this.videosCurrentTimestamp[id] = { currentTime }
      }
    },
    removeVideoCurrentTimestamp (videoAsmtId) {
      if (this.videosCurrentTimestamp.hasOwnProperty(videoAsmtId)) {
        delete this.videosCurrentTimestamp[videoAsmtId]
      }
    },
    setSearchText (searchText) {
      this.searchText = searchText
    },
    clearRecommendedModal () {
      this.selectedVideo = {}
      this.selectedVideoType = videoFilterEnums.FAVORITES
      this.searchText = ''
    },
    setCurrentVideoAsmtId (videoAsmtId) {
      this.currentVideoAsmtId = videoAsmtId
    },
    editComment ({ commentId, updatedComment }) {
      const index = this.comments.findIndex(comment => comment.commentId === commentId)
      this.comments[index].comment = updatedComment
    },
    deleteComment (commentId) {
      const index = this.comments.findIndex(comment => comment.commentId === commentId)
      const timestampIndex = this.feedbackTimestamps.findIndex(fb =>
        fb.videoFeedbackType === videoFeedbackTypes.COMMENT && fb.feedbackId === commentId)
      this.comments.splice(index, 1)
      this.feedbackTimestamps.splice(timestampIndex, 1)
    },
    resetVideoFeedbackState () {
      this.$reset()
    }
  }
})
