const sizes = {
  XXS: { diameter: 32, fontSize: 11 },
  XS: { diameter: 40, fontSize: 12 },
  SM: { diameter: 48, fontSize: 13 },
  MD: { diameter: 56, fontSize: 14 },
  LG: { diameter: 72, fontSize: 19 },
  XL: { diameter: 80, fontSize: 22 }
}

export default Object.freeze(sizes)
