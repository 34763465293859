import { defineStore } from 'pinia'
import commonLayouts from '@/consts/common-layouts'

export const useLayoutStore = defineStore('layout', {
  persist: { storage: sessionStorage },
  state: () => ({
    layout: commonLayouts.SIMPLE_LAYOUT,
    mobileSidebar: {
      hide: false,
      showText: ''
    },
    windowWidth: window.innerWidth
  }),
  actions: {
    showMobileSidebar () {
      this.setMobileSidebar({ hide: false })
    },
    hideMobileSidebar (showText) {
      this.setMobileSidebar({ hide: true, showText })
    },
    setMobileSidebar (sidebar) {
      if ('hide' in sidebar) {
        this.mobileSidebar.hide = sidebar.hide
      }
      if ('showText' in sidebar) {
        this.mobileSidebar.showText = sidebar.showText
      }
    },
    setWindowWidth (width) {
      this.windowWidth = width
    },
    setLayout (layout) {
      this.layout = layout
    }
  }
})
