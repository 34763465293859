import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import cssVariables from '@/assets/scss/_variables.module.scss'

const light = {
  colors: {
    primary: cssVariables.primaryColor,
    background: '#FBFBFB',
    secondary: cssVariables.primaryColor,
    accent: cssVariables.primaryColor,
    error: cssVariables.errorRed,
    info: '#E5F5FC',
    success: cssVariables.successGreen,
    warning: '#FFB526',
    fontDark: cssVariables.fontDark,
    fontLight: cssVariables.fontLight
  }
}

export default createVuetify({
  theme: {
    defaultTheme: 'light',
    themes: {
      light
    }
  },
  icons: {
    iconfont: 'md'
  },
  defaults: {
    VDialog: {
      attach: '#app'
    }
  }
})
