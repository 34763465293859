import { defineStore } from 'pinia'
import { workshopApi } from '@/workshop/workshop-api'

export const useParticipantUserDetailsStore = defineStore('participantUserDetails', {
  state: () => ({
    userDetails: null
  }),
  actions: {
    async fetchMgmtFacultyDetails (userId) {
      const res = await workshopApi.get(`/workshop-user/${userId}?projection=facultyUserDetails`)
      this.userDetails = res.data
    },
    async fetchMgmtAttendeeDetails (userId) {
      const res = await workshopApi.get(`/workshop-user/${userId}?projection=attendeeUserDetails`)
      this.userDetails = res.data
    },
    async fetchMgmtFacultyAttendeeDetails (userId) {
      const res = await workshopApi.get(`/workshop-user/${userId}?projection=facultyAttendeeUserDetails`)
      this.userDetails = res.data
    }
  }
})
