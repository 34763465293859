<template>
  <ul class="star-list">
    <li
      v-for="index in 5"
      :key="index"
      class="star-list-item"
      data-cy="star-btn"
      @click="$emit('update:modelValue', index)"
      @mouseover="stars = index"
      @mouseleave="stars = modelValue"
    >
      <div :class="{'star': true, 'star-active': index <= stars}"></div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Number,
      required: true
    },
    size: {
      type: Number,
      required: false,
      default: 36
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      stars: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.star-list {
  width: 100%;
  display: flex;
  padding: 8px 0px;
}

.star-list-item {
  display: inline-flex;
  justify-content: center;
  flex-grow: 1;
  cursor: pointer;
  font-family: "Open Sans";
}

.star {
  &::before {
    content: "\2606";
  }

  margin: 0px auto;
  font-size: 36px;
  color: $primary-digital-teal-default;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
  font-weight: bold;
}

.star-active {
  &::before {
    content: "\2605";
  }

  color: #FFEB3B;
}

</style>
