import { defineStore } from 'pinia'
import { workshopApi } from '@/workshop/workshop-api'
import workshopRoles from '@/workshop/consts/workshop-roles'
import { useParticipantProfileDetailsStore } from '@/workshop/stores/participant/participant-profile-details'
import { useWorkshopHistoryCountStore } from '@/workshop/stores/admin/workshop-history-count'

export const useMeStore = defineStore('workshopMe', {
  persist: { storage: sessionStorage },
  state: () => ({
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    fullName: '',
    role: '',
    isFaculty: false,
    isAttendee: false
  }),
  getters: {
    isAdmin: state => state.role === workshopRoles.WORKSHOP_ADMIN.value,
    isAdministrativeRole: state => {
      return state.role === workshopRoles.WORKSHOP_ADMIN.value ||
        state.role === workshopRoles.CLINICAL_EDUCATION.value ||
        state.role === workshopRoles.FACILITATOR.value
    },
    isManagerialRole: state => {
      return state.role === workshopRoles.WORKSHOP_ADMIN.value ||
        state.role === workshopRoles.CLINICAL_EDUCATION.value
    },
    isParticipant: state => state.role === workshopRoles.PARTICIPANT.value,
    isPureFaculty: state => state.isFaculty && !state.isAttendee,
    isPureAttendee: state => state.isAttendee && !state.isFaculty
  },
  actions: {
    async getMe () {
      const resp = await workshopApi.get('/me')
      this.setMe(resp.data)
      if (!this.isAdministrativeRole) {
        const participantProfileDetailsStore = useParticipantProfileDetailsStore()
        await participantProfileDetailsStore.getProfilePhoto()
      } else {
        const { fetchWorkshopTotalCounts } = useWorkshopHistoryCountStore()
        await fetchWorkshopTotalCounts()
      }
    },
    setMe (meDto) {
      this.$patch({
        ...meDto,
        fullName: `${meDto.firstName} ${meDto.lastName}`
      })
    },
    resetPasswordEmail (email) {
      return workshopApi.post(`/user/forgot-password?email=${email}`)
    },
    resetPassword ({ resetToken, password }) {
      return workshopApi.patch(`/user/reset-password?token=${resetToken}`, { password })
    }
  }
})
