import { useNotificationStore } from '@/residency/stores/notification'
import { useScheduleStore } from '@/residency/stores/schedule'

/**
 * This module adds and removes calendar events from the store.
 */
const response = {
  // add a new event to the store
  '/user/topic/schedule/update': (res) => {
    const event = JSON.parse(res.body)
    const { addScheduleEventFromWebsocket } = useScheduleStore()
    addScheduleEventFromWebsocket(event)
  },
  // remove an event from the store
  '/user/topic/schedule/delete': (res) => {
    const { deleteNotification } = useNotificationStore()
    const { deleteScheduleEvent } = useScheduleStore()
    const eventId = JSON.parse(res.body)
    deleteNotification(eventId)
    deleteScheduleEvent(eventId)
  }
}

export default {
  response
}
