<template>
  <footer class="footer-container print-hide">
    <div class="legal-notice">
      <div class="links">
        <slot/>
        <a href="mailto:simsei@appliedmedical.com" target="_blank" class="footer-link">Contact us</a>
      </div>
      <div class="copyright">© 2024 Applied Medical Resources Corporation | All rights reserved</div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/_print.scss";
@import "@/assets/scss/footer.scss";

@include print;

.footer-container {
  background-color: #363636;
  color: $footer-link-color;
  font-size: 12px;

  .links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .footer-link {
    @include footer-link;
  }

  .legal-notice {
    display: flex;
    align-items: center;
  }
}

@media (max-width: ($max-mobile-width + 40px)) {
  .legal-notice {
    flex-direction: column;
    justify-content: space-around;
    height: $footer-mobile-height;

    // Fix legal link wrapping below ~362px
    @media (max-width: 362px) {
      font-size: 10px;
    }
  }

  .copyright {
    text-align: center;
  }
}

@media (min-width: $min-desktop-width) {
  .legal-notice {
    height: $footer-height;
    justify-content: space-around;
  }
}

// Below width of 900px, links and copyright run into one another if font-size is 15px,
// therefore let font size remain 12px until media width is at least 900px.
@media (min-width: 900px) {
  .legal-notice {
    font-size: 12px;
  }
}
</style>
