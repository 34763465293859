<template>
  <router-link v-if="to" :to="to" class="base-button-anchor">
    <button
      :id="$attrs.id"
      class="btn-router-link"
      :class="[...classes, $attrs.class]"
      :style="[styles, $attrs.style]"
      v-ripple="!isDisabled"
      @click="click"
    >
      <slot></slot>
    </button>
  </router-link>
  <a
    v-else-if="href"
    :id="$attrs.id"
    :href="href"
    class="base-btn"
    :class="[...classes, $attrs.class]"
    :style="[styles, $attrs.style]"
    v-ripple="!isDisabled"
    @click="click"
  >
    <slot></slot>
  </a>
  <button
    v-else
    :id="$attrs.id"
    class="base-btn"
    :class="[...classes, $attrs.class]"
    :style="[styles, $attrs.style]"
    v-ripple="!isDisabled"
    @click="click"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    transparent: {
      type: Boolean,
      required: false
    },
    outlined: {
      type: Boolean,
      required: false
    },
    bold: {
      type: Boolean,
      required: false,
      default: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    width: {
      type: [String, Number],
      required: false
    },
    to: {
      type: String,
      required: false
    },
    submit: {
      type: Boolean,
      required: false
    },
    colorScheme: {
      type: String,
      required: false,
      default: 'dark-teal',
      validator: (colorScheme) => {
        // Color schemes can be found in assets/scss/buttons.scss
        return [
          'dark-teal', 'light-teal', 'heavy-teal', 'gray',
          'light-red', 'dark-red', 'magenta-white', 'grey-black',
          'unselected-lab', 'unselected-other'
        ].indexOf(colorScheme) !== -1
      }
    },
    textColor: {
      type: String,
      required: false
    },
    backgroundColor: {
      type: String,
      required: false
    },
    fontSize: {
      type: String,
      required: false
    },
    href: {
      type: String,
      required: false
    }
  },
  emits: ['click'],
  computed: {
    isDisabled () {
      return this.disabled
    },
    getVariant () {
      if (this.transparent) {
        return 'transparent'
      } else if (this.outlined) {
        return 'outlined'
      } else {
        return 'contained'
      }
    },
    classes () {
      const classes = []
      if (this.disabled) {
        classes.push(`btn-${this.getVariant}-disabled`)
      } else if (this.backgroundColor) {
        classes.push('btn-custom')
      } else {
        classes.push(`btn-${this.getVariant}-${this.colorScheme}`)
      }

      if (this.bold) {
        classes.push('bold')
      }

      return classes
    },
    styles () {
      const customStyle = {}
      if (this.textColor) {
        customStyle.color = this.textColor
      }

      if (this.backgroundColor) {
        customStyle.backgroundColor = this.backgroundColor
      }

      if (this.width) {
        if (isNaN(this.width)) {
          customStyle.width = this.width
        } else {
          customStyle.width = `${this.width}px`
        }
      }

      if (this.fontSize) {
        customStyle.fontSize = this.fontSize
      }

      return customStyle
    }
  },
  methods: {
    click (e) {
      if ((!this.to && !this.submit) || this.disabled) {
        e.preventDefault()
      }
      if (!this.disabled) {
        this.$emit('click', e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/buttons.scss";

.base-btn :deep(img) {
  margin: 4px;
}

.base-button-anchor {
  text-decoration: none;
}
</style>
