import { defineStore } from 'pinia'
import { cancelUploadTaskRequestStatus } from '@/residency/views/video/video-enums'
import log from '@/residency/utils/log'
import { simseiApi } from '@/residency/app-props'
import { useVideoAsmtStore } from './video-asmt'

export const usePausedUploadStore = defineStore('pausedUpload', {
  state: () => ({
    // key: uploadId, value: { totalSize, uploadedSize, secondsRemaining, isPaused, cancelRequest, uploadTask }
    videoUploadProgressInfo: new Map(),
    pausedUploadTasks: new Map() // Map<uploadId, Object (info)>
  }),
  getters: {
    listOfPausedUploadTasks: (state) => Array.from(state.pausedUploadTasks.values())
  },
  actions: {
    async startResumeUpload ({ file, uploadId, onStarted }) {
      await simseiApi.patch(`tus/upload/resume?taskId=${uploadId}`)
      const { uploadWithTus } = useVideoAsmtStore()
      const creationResponse = uploadWithTus({
        file,
        uploadUrl: `/api/v1/tus/upload/${uploadId}`,
        onUploadTaskCreated: (upload) => {
          onStarted()
          this.initializeUploadProgress({ uploadId, uploadTask: upload })
        }
      })
      const uploadInfo = (await creationResponse).data
      return uploadInfo
    },
    async pauseUploadTask (uploadId) {
      if (!this.videoUploadProgressInfo.has(uploadId)) {
        log.warn('Cannot pause upload task because it does not exist')
        return false
      } else {
        try {
          const upload = this.videoUploadProgressInfo.get(uploadId).uploadTask
          upload.abort()
          await simseiApi.patch(`tus/upload/pause?taskId=${uploadId}`)
        } catch (e) {
          log.error('Failed to pause upload task', e)
          return false
        }

        this.updateUploadProgress({
          uploadId,
          totalSize: this.videoUploadProgressInfo.get(uploadId).totalSize,
          uploadedSize: this.videoUploadProgressInfo.get(uploadId).uploadedSize,
          secondsRemaining: this.videoUploadProgressInfo.get(uploadId).secondsRemaining,
          isPaused: true,
          cancelRequest: this.videoUploadProgressInfo.get(uploadId).cancelRequest,
          uploadTask: this.videoUploadProgressInfo.get(uploadId).uploadTask
        })

        return true
      }
    },
    createCancelUploadTaskRequest (uploadId) {
      if (!this.videoUploadProgressInfo.has(uploadId)) {
        log.warn('Cannot cancel upload task because it does not exist')
        return false
      } else {
        this.updateUploadProgress({
          uploadId,
          totalSize: this.videoUploadProgressInfo.get(uploadId).totalSize,
          uploadedSize: this.videoUploadProgressInfo.get(uploadId).uploadedSize,
          secondsRemaining: this.videoUploadProgressInfo.get(uploadId).secondsRemaining,
          isPaused: this.videoUploadProgressInfo.get(uploadId).isPaused,
          cancelRequest: cancelUploadTaskRequestStatus.PENDING,
          uploadTask: this.videoUploadProgressInfo.get(uploadId).uploadTask
        })

        const upload = this.videoUploadProgressInfo.get(uploadId).uploadTask
        upload.abort()
        return true
      }
    },
    denyCancelUploadTaskRequest (uploadId) {
      if (!this.videoUploadProgressInfo.has(uploadId)) {
        log.warn('Cannot deny cancel upload task request because it does not exist')
        return false
      } else {
        this.updateUploadProgress({
          uploadId,
          totalSize: this.videoUploadProgressInfo.get(uploadId).totalSize,
          uploadedSize: this.videoUploadProgressInfo.get(uploadId).uploadedSize,
          secondsRemaining: this.videoUploadProgressInfo.get(uploadId).secondsRemaining,
          isPaused: this.videoUploadProgressInfo.get(uploadId).isPaused,
          cancelRequest: null,
          uploadTask: this.videoUploadProgressInfo.get(uploadId).uploadTask
        })

        const upload = this.videoUploadProgressInfo.get(uploadId).uploadTask
        upload.start()
        return true
      }
    },
    cancelUploadTask (uploadId) {
      this.videoUploadProgressInfo.delete(uploadId)
      this.terminatePausedUploadTask(uploadId)
    },
    async fetchPausedUploadTasks () {
      const res = await simseiApi.get('tus/upload/my-paused-tasks')
      this.setPausedUploadTasks(res.data)
    },
    async terminatePausedUploadTask (uploadId) {
      await simseiApi.patch(`tus/upload/cancel?taskId=${uploadId}`)
      this.fetchPausedUploadTasks()
    },
    initializeUploadProgress ({ uploadId, uploadTask }) {
      this.videoUploadProgressInfo.set(uploadId, {
        totalSize: -1,
        uploadedSize: -1,
        secondsRemaining: -1,
        isPaused: false,
        cancelRequest: null,
        uploadTask
      })
    },
    updateUploadProgress (
      { uploadId, totalSize, uploadedSize, secondsRemaining, isPaused, cancelRequest, uploadTask }
    ) {
      // trigger a reactivity update
      const mapCopy = new Map(this.videoUploadProgressInfo)
      mapCopy.set(uploadId, {
        totalSize,
        uploadedSize,
        secondsRemaining,
        isPaused,
        cancelRequest,
        uploadTask
      })
      this.videoUploadProgressInfo = mapCopy
    },
    setPausedUploadTasks (pausedUploadTasks) {
      // build map from array
      const tasks = new Map()
      for (const task of pausedUploadTasks) {
        tasks.set(task.id, task)
      }

      this.pausedUploadTasks = tasks
    },
    removeUploadProgress (uploadId) {
      this.videoUploadProgressInfo.delete(uploadId)
    }
  }
})
