import { defineStore } from 'pinia'
import { simseiApi } from '@/residency/app-props'

export const useUserStore = defineStore('user', {
  persist: { storage: sessionStorage },
  state: () => ({
    // Includes users who are neither residents nor faculty, as well as test administrative users.
    internalUsers: [],
    // Includes the official TMs who manage programs with customers. This list does not include the test adminstrative users.
    officialInternalUsers: [],
    userToEdit: null
  }),
  actions: {
    setUserToEdit (user) {
      this.userToEdit = user
    },
    resendRegistrationEmailToUser (existingUser) {
      return simseiApi.post(`/email/send/resend-registration?email=${encodeURIComponent(existingUser.email)}`)
    },
    bulkResendRegistrationEmailToUsers: (selectedUsers) => {
      return simseiApi.post('/email/send/bulk/resend-registration', selectedUsers)
    },
    async fetchInternalUsers () {
      const internalUsers = await simseiApi.get('/user/search/findAllInternalAccounts', {
        params: {
          projection: 'internalUser'
        }
      })
      const users = internalUsers.data._embedded.user
      const officialUsers = users.filter(user => !user.isInternalAdminUser)
      this.internalUsers = users
      this.officialInternalUsers = officialUsers
      return users
    },
    async createInternalUser (internalUser) {
      const res = await simseiApi.post('/user/internal', internalUser)
      this.fetchInternalUsers()
      return res
    },
    async editInternalUser (payload) {
      const original = payload.original
      const edited = payload.edited
      const res = await simseiApi.patch(`/user/internal?userEmail=${original.email}`, edited)
      this.fetchInternalUsers()
      return res
    },
    async deleteInternalUser (userEmail) {
      const res = await simseiApi.delete(`/user/internal?userEmail=${userEmail}`)
      this.fetchInternalUsers()
      return res
    },
    registerUser ({ registrationToken, user }) {
      return simseiApi.patch(`/user/register?token=${registrationToken}`, user)
    },
    async findUserByRegistrationToken (registrationToken) {
      const response = await simseiApi.get('/user/search/findByUserDetails_RegistrationToken_Token', {
        params: {
          token: registrationToken,
          projection: 'inlineUserRole'
        }
      })
      return response.data
    },
    resetPasswordEmail (email) {
      return simseiApi.post(`/email/send/reset-password?email=${email}`)
    },
    resetPassword ({ resetToken, password }) {
      return simseiApi.patch(`/user/reset-password?token=${resetToken}`, { password })
    },
    async getRoles () {
      const resp = await simseiApi.get('/role')
      return resp.data._embedded.role
    },
    async fetchResidentsByGroupId ({ groupId, projection }) {
      const params = { id: groupId, projection: projection }
      const response = await simseiApi.get('/user/search/findByGroupId', { params: params })
      return response.data._embedded.user
    },
    async fetchFacultyByGroupId ({ groupId, projection }) {
      const params = { id: groupId, projection: projection }
      const response = await simseiApi.get('/user/search/findFacultyByGroupId', { params: params })
      return response.data._embedded.user
    },
    async fetchResidentsByProgramId ({ programId, projection }) {
      const params = { id: programId, projection: projection }
      const response = await simseiApi.get('/user/search/findResidentsByProgramId', { params: params })
      return response.data._embedded.user
    },
    async fetchFacultyByProgramId ({ programId, projection }) {
      const params = { id: programId, projection: projection }
      const response = await simseiApi.get('/user/search/findFacultyByProgramId', { params: params })
      return response.data._embedded.user
    }
  }
})
