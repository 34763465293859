import { defineStore } from 'pinia'

export function createFilterStore (id, defaultMenu) {
  return defineStore(id, {
    state: () => ({
      textSearchFilter: '',
      filterMenu: defaultMenu
    }),
    actions: {
      setTextSearchFilter (filterValue) {
        this.textSearchFilter = filterValue
      },
      updateFilterMenu ({ filterType, filterValue }) {
        this.filterMenu[filterType] = filterValue
      },
      resetFilterMenu () {
        this.filterMenu = JSON.parse(JSON.stringify(defaultMenu))
      }
    }
  })
}
