<template>
  <router-link :to="`/${linkOnClick}`" class="header-logo">
    <img :src="simseiLogo" />
  </router-link>
</template>

<script>
import cdn from '@/consts/cdn'

export default {
  props: {
    linkOnClick: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      simseiLogo: cdn.BRANDING + '/simsei-simulation-logo.svg'
    }
  }
}
</script>

<style scoped lang="scss">
.header-logo {
  max-height: 40px;
  max-width: 110px;
  margin-right: 2%;

  @media (min-width: $lg) {
    margin-right: 6%;
    margin-left: 2%;
  }

  img {
    height: 40px;
  }
}
</style>
