import { defineStore } from 'pinia'
import { simseiApi } from '@/residency/app-props'
import toastTypes from '@/components/toast/toast-types'
import { videoTypeEnums } from '@/residency/views/video/video-enums'
import generalEndpoints from '@/residency/router/endpoints/general-endpoints'
import { useMeStore } from '@/residency/stores/me'
import { useSharedVideoStore } from './shared-video'
import { useToastNotificationStore } from '@/stores/toast-notification'
import { useVideoAsmtStore } from './video-asmt'

const getVideoURL = async (videoId, videoType) => {
  const meStore = useMeStore()
  if (meStore.isInstructor) {
    const res = await simseiApi.get(`/shared-library/video/${videoId}`)
    const sharedVideoId = res.data
    return `/${generalEndpoints.VIDEO_LIBRARY}/${sharedVideoId}`
  }
  return `/${generalEndpoints.VIDEO_TECHNICAL_ASMT}/${videoTypeEnums[videoType]}/${videoId}?playback-view=true`
}

const updateFacultyVideoLibrary = () => {
  const sharedVideoStore = useSharedVideoStore()
  sharedVideoStore.$reset()
  sharedVideoStore.fetchAllSharedVideos()
  sharedVideoStore.fetchSavedVideos()
}

export const useVideoProcessingStore = defineStore('videoProcessing', {
  state: () => ({
    processingSubmissions: [],
    failedSubmissions: []
  }),
  actions: {
    async acknowledgeSubmission (videoAsmtId) {
      const res = await simseiApi.patch(`/submission-processing/ack-asmt/${videoAsmtId}`)
      if (!Object.keys(res.data).length) {
        // user has no processing submissions
        this.processingSubmissions = []
        this.failedSubmissions = []
      } else {
        const processing = []
        const failed = []
        const videosSubmissions = res.data
        for (const subId in videosSubmissions) {
          const submission = videosSubmissions[subId]
          if (!submission.isDone && !submission.hasError) {
            processing.push({ asmtId: subId, details: submission })
          } else if (submission.isDone && submission.hasError) {
            failed.push({ asmtId: subId, details: submission })
          } else {
            this.$log.error(`Unexpected state in acknowledgeSubmission. submission.isDone: ${submission.isDone}, submission.hasError: ${submission.hasError}`)
          }
        }
        this.processingSubmissions = processing
        this.failedSubmissions = failed
      }
    },
    async fetchProcessingSubmissions () {
      const res = await simseiApi.get('/submission-processing/asmts-by-user')
      if (!Object.keys(res.data).length) {
        // user has no processing submissions
        this.processingSubmissions = []
        this.failedSubmissions = []
      } else {
        const processing = []
        const failed = []
        let hasNewSubmissions = false
        let videoType = null
        const videosSubmissions = res.data
        const { enqueueToastNotification } = useToastNotificationStore()
        for (const subId in videosSubmissions) {
          const submission = videosSubmissions[subId]
          if (!submission.isDone && !submission.hasError) {
            processing.push({ asmtId: subId, details: submission })
          } else if (submission.isDone && !submission.hasError) {
            // on succeeded submissions, we just need to dispatch a toast notification
            videoType = videosSubmissions[subId].videoType
            const vidURL = await getVideoURL(subId, videoType)
            const urlStyle = 'color:rgba(0,0,0,0.7);font-weight:bold;text-decoration:underline;'
            const urlText = `<a href="${vidURL}" style="${urlStyle}">Click here to view the video.</a>`
            enqueueToastNotification({
              msg: `Video processing is completed. ${urlText}`,
              useUnsafeHTML: true,
              type: toastTypes.SUCCESS,
              onOpen: async () => {
                simseiApi.patch(`/submission-processing/ack-asmt/${subId}`)
              }
            })
            hasNewSubmissions = true
            videoType = submission.videoType
          } else if (submission.isDone && submission.hasError) {
            failed.push({ asmtId: subId, details: submission }) // save info to render "ghost" row in table
            // on failed submissions, we need to dispatch a toast notification only once
            if (!submission.silenceNotifications) {
              enqueueToastNotification({
                msg: 'Video processing has failed. Please try to upload the video again.',
                type: toastTypes.ERROR,
                onOpen: async () => {
                  // mark the submission as silenced so that we don't show the notification again
                  simseiApi.patch(`/submission-processing/silence-asmt/${subId}`)
                }
              })
            }
          } else {
            this.$log.error(`Unexpected state in fetchProcessingSubmissions. submission.isDone: ${submission.isDone}, submission.hasError: ${submission.hasError}`)
          }
        }
        this.processingSubmissions = processing
        this.failedSubmissions = failed

        if (hasNewSubmissions && videoType) {
          // update list of submissions for video library table
          const { getSelfCreatedVideoAsmts } = useVideoAsmtStore()
          getSelfCreatedVideoAsmts(videoTypeEnums[videoType])

          const meStore = useMeStore()
          // update the video library with the faculty's newly uploaded/recorded video
          if (meStore.isInstructor) await updateFacultyVideoLibrary()
        } else if (hasNewSubmissions && !videoType) {
          this.$log.error(`Cannot getSelfCreatedVideoAsmts with videoType [${videoType}]`)
        }

        if (processing.length > 0) {
          // schedule call to fetch updated info about processing submissions
          setTimeout(() => {
            this.fetchProcessingSubmissions()
          }, 1500)
        }
      }
    }
  }
})
