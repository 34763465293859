import { defineStore } from 'pinia'
import { workshopApi } from '@/workshop/workshop-api'

export const useWorkshopHistoryCountStore = defineStore('workshopHistoryCount', {
  persist: { storage: sessionStorage },
  state: () => ({
    pastWorkshopsTotalCount: 0,
    upcomingWorkshopsTotalCount: 0
  }),
  actions: {
    async fetchWorkshopTotalCounts () {
      await this.fetchPastWorkshopsTotalCount()
      await this.fetchUpcomingWorkshopsTotalCount()
    },
    async fetchPastWorkshopsTotalCount () {
      const res = await workshopApi.get('/workshop/search/totalCountPastWorkshops')
      this.pastWorkshopsTotalCount = res.data
    },
    async fetchUpcomingWorkshopsTotalCount () {
      const res = await workshopApi.get('/workshop/search/totalCountUpcomingWorkshops')
      this.upcomingWorkshopsTotalCount = res.data
    },
    setPastWorkshopsTotalCount (count) {
      this.pastWorkshopsTotalCount = count
    },
    setUpcomingWorkshopsTotalCount (count) {
      this.upcomingWorkshopsTotalCount = count
    },
    incrementUpcomingWorkshopsTotalCount () {
      this.upcomingWorkshopsTotalCount++
    }
  }
})
