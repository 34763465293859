import { defineStore } from 'pinia'

export const useVideoSubmissionStore = defineStore('videoSubmission', {
  persist: { storage: sessionStorage },
  state: () => ({
    surgicalData: {
      approach: '',
      procedure: ''
    },
    otherVideoTitle: ''
  }),
  getters: {
    getSurgicalApproach: state => state.surgicalData.approach,
    getSurgicalProcedure: state => state.surgicalData.procedure
  },
  actions: {
    setSurgicalApproachProcedure (surgicalInfo) {
      surgicalInfo.procedure = surgicalInfo.procedure.trim()
      this.surgicalData = surgicalInfo
    },
    setOtherVideoTitle (videoTitle) {
      videoTitle = videoTitle.trim()
      this.otherVideoTitle = videoTitle
    }
  }
})
