import { defineStore } from 'pinia'
import { simseiApi } from '@/residency/app-props'

export const useAdminManageUsersStore = defineStore('adminManageUsers', {
  state: () => ({
    userGroupUsers: [],
    programFaculty: [],
    programProctors: [],
    unregisteredUsers: [],
    roleIds: {},
    defaultCurriculums: []
  }),
  getters: {
    adminUnexpiredGroups (state) {
      return state.userGroupUsers.filter(group => !group.isExpired && !group.isUnassignedGroup)
    },
    adminExpiredUserGroups (state) {
      return state.userGroupUsers.filter(group => group.isExpired && !group.isUnassignedGroup)
    },
    adminUnassignedGroup (state) {
      return state.userGroupUsers.find(group => group.isUnassignedGroup)
    },
    areUsersFetched (state) {
      return state.userGroupUsers.length || state.programFaculty.length || state.programProctors.length
    }
  },
  actions: {
    async fetchUserGroupUsers (programId) {
      const resp = await simseiApi.get(`/user-group/program/${programId}`)
      this.userGroupUsers = resp.data
    },
    async fetchProgramFaculty (programId) {
      const resp = await simseiApi.get('/user/search/findFacultyByProgramId', {
        params: {
          id: programId,
          projection: 'inlineManageFacultyUserDetails'
        }
      })
      this.programFaculty = resp.data._embedded.user
    },
    async fetchProgramProctors (programId) {
      const resp = await simseiApi.get(`/program/proctors/${programId}`)
      this.programProctors = resp.data
    },
    async fetchUnregisteredUsers (programId) {
      const resp = await simseiApi.get(`/user/unregistered-users/${programId}`)
      this.unregisteredUsers = resp.data
    },
    async fetchRoleIds () {
      const roleIdArr = (await simseiApi.get('/role')).data._embedded.role
      const roleIdMap = {}
      roleIdArr.forEach(role => {
        roleIdMap[role.role] = role.id
      })
      this.roleIds = roleIdMap
    },
    async updateResidentCurrentUserGroup ({ user, newGroupId, oldGroupId }) {
      await simseiApi.patch('/user-group/change-user-group', user, {
        params: { oldGroupId, newGroupId }
      })
    },
    async reactivateUser ({ userId }) {
      await simseiApi.patch('/user/reactivate-user', { id: userId })
    },
    async inactivateUser ({ userId }) {
      await simseiApi.patch('/user/inactivate-user', { id: userId })
    },
    async createNewGroup (groupInfo) {
      await simseiApi.post('/user-group/create-group', groupInfo)
      await this.fetchUserGroupUsers(groupInfo.parentProgramId)
    },
    async editGroup ({ gid, payload }) {
      await simseiApi.patch(`/user-group/${gid}/edit-group`, payload)
    },
    async updateProctorGroups ({ userId, proctorGroupIds }) {
      const user = {
        id: userId,
        proctorGroups: proctorGroupIds.map(groupId => ({ id: groupId }))
      }
      await simseiApi.patch('/user/update-proctor-groups', user)
    },
    async batchAddUnregisteredUsers (payload) {
      return simseiApi.post(`/user/batch/${payload.programId}?sendEmail=${payload.sendEmail}`, payload.users)
    },
    async batchUpdateCurrentUserGroup (users) {
      await simseiApi.patch('user-group/batch/change-user-group', users)
    },
    async batchInactivateUsers (users) {
      await simseiApi.patch('/user/batch/inactivate-users', users)
    },
    clearUnregisteredUsers () {
      this.unregisteredUsers = []
    },
    async deleteUser (id) {
      await simseiApi.delete(`/user/${id}`)
    },
    async fetchDefaultCurriculums () {
      const resp = await simseiApi.get('/user-group/default-curriculum')
      this.defaultCurriculums = resp.data
    }
  }
})
