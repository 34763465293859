<template>
  <div class="base-card">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseCard'
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.base-card {
  border-radius: 8px;
  background-color: $surface-color;
  overflow: hidden;
}
</style>
