import log from '@/residency/utils/log'
import SockJS from 'sockjs-client'
import Stomp from 'webstomp-client'
import { dispatchCheckAccessToken } from '@/residency/app-props'
import scheduleModule from './modules/schedule'
import notificationModule from './modules/notification'
import videoModule from './modules/video'
import { useAuthStore } from '@/residency/stores/auth'

const websocketModules = [scheduleModule, notificationModule, videoModule]

/**
 * A singleton to manage the websocket connection
 */
export default class WebSocketManager {
  static instance = null
  constructor () {
    if (!WebSocketManager.instance) {
      WebSocketManager.instance = this
      this.socket = null
      this.stompClient = null
      this.connected = false

      /**
       * Map of websocket URLs and their event listeners
       * Ex:
       * {
       *   "/user/topic/video/cdn-done": Object { 18: function, 21: function },
       *   "/user/topic/video/cdn-error": Object {  },
       *   "/user/topic/video/stream-error": Object { 18: function }
       * }
       */
      this.listeners = {}

      /**
       * Map of listeners to delete on 'beforeUnmount'
       * Ex:
       * {
       *   "18": ["/user/topic/video/cdn-done", "/user/topic/video/stream-error"],
       *   "21": ["/user/topic/video/cdn-done"]
       * }
       */
      this.listenersToDelete = {}

      /**
       * Listeners are added to this queue when the websocket is not connected,
       * and are added to the listeners map when the websocket connects.
       */
      this.beforeConnectedQueue = []
    }
    return WebSocketManager.instance
  }

  async connect () {
    const authStore = useAuthStore()
    const self = WebSocketManager.getInstance()
    if (self.connected || !authStore.tokenInfo.accessToken) return

    const token = await dispatchCheckAccessToken()
    self.socket = new SockJS(`${process.env.VUE_APP_BASE_API}/ws`)
    // Heartbeat of 25 seconds "is in line with the following IETF recommendation for public Internet applications."
    // https://docs.spring.io/spring-framework/docs/4.1.0.RC1/spring-framework-reference/html/websocket.html
    self.stompClient = Stomp.over(self.socket, {
      debug: false,
      heartbeat: {
        incoming: 25000,
        outgoing: 25000
      }
    })
    const auth = { Authorization: token }

    const handleOnStompConnect = WebSocketManager.getInstance().onStompConnect.bind(self)
    const handleOnStompError = WebSocketManager.getInstance().onStompError.bind(self)
    self.stompClient.connect(auth, handleOnStompConnect, handleOnStompError)
  }

  disconnect () {
    if (!this.connected) return
    this.connected = false
    if (this.stompClient) {
      this.stompClient.disconnect()
    }
  }

  /**
   * Register a listener for a websocket event. Prefer to utilize the useWebsocketListener composable
   * instead of using this function directly.
   *
   * @param {String} uid Unique identifier for the component so that this singleton can track all listeners
   *            and their respective components
   * @param {String} dest Name of the websocket event
   * @param {Function} callback Callback when a message is received for the dest
   * @param {Object} options Optional parameters for the listener.
   *                         - shouldAck: Whether to acknowledge the event (default: false)
   */
  addListener (uid, dest, callback, options) {
    if (!this.connected) {
      this.beforeConnectedQueue.push({ uid, dest, callback, options })
      return
    }
    if (!this.listeners[dest]) {
      log.error(`Invalid event name: ${dest}`)
      return
    }

    this.listeners[dest][uid] = { callback, options }
    this.#addToDelete(uid, dest)
  }

  /**
   * Removes the listeners for a given component. Prefer to utilize the useWebsocketListener composable
   * instead of using this function directly.
   *
   * @param {String} uid Unique identifier of the component to remove the listeners for.
   */
  removeListeners (uid) {
    if (this.listenersToDelete[uid]) {
      this.listenersToDelete[uid].forEach(dest => delete this.listeners[dest][uid])
      delete this.listenersToDelete[uid]
    }
  }

  static getInstance () {
    if (!WebSocketManager.instance) {
      WebSocketManager.instance = new WebSocketManager()
    }
    return WebSocketManager.instance
  }

  onStompConnect (frame) {
    log.info('SockJS has connected!')
    if (this.socket.transport !== 'websocket') {
      // some browsers might not support WebSocket API.
      // we mainly expect modern browsers to be connecting, so we want to be altered when a browser does not
      // use the WebSocket API by default.
      log.warn(`User connected with non-websocket SockJS transport: ${this.socket.transport}`)
    }

    this.connected = true
    this.#setupModules(websocketModules)
    this.beforeConnectedQueue.forEach(item => this.addListener(item.uid, item.dest, item.callback, item.options))
  }

  onStompError (err) {
    log.warn(`SockJS connection closed: ${JSON.stringify(err)}`)
    this.connected = false
    if (this.stompClient) {
      this.stompClient.disconnect()
    }
    console.info('SockJS disconnected. Reconnecting...')

    if (err.headers && err.headers.message) {
      // STOMP protocal encodes special characters. More information can be found here:
      // http://stomp.github.io/stomp-specification-1.2.html#Value_Encoding
      try {
        const message = err.headers.message
          .replaceAll('\\r', '\r')
          .replaceAll('\\n', '\n')
          .replaceAll('\\c', ':')
          .replaceAll('\\\\', '\\')
        const errorMsg = JSON.parse(message)
        switch (errorMsg.errorCode) {
          case 401:
            console.log('Access token has expired')
            return
        }
      } catch (err) {
        // Not a JSON string, ignore
      }
    }
    setTimeout(this.connect, 1000)
  }

  #setupModules (modules) {
    for (const module of modules) {
      // Sets up websocket listeners for modules
      for (const dest in module.response) {
        this.stompClient.subscribe(dest, function (res) { module.response[dest](res) })
      }

      // Sets up websocket listeners for components
      for (const name in module.listeners) {
        const dest = module.listeners[name]
        this.stompClient.subscribe(dest, (resp) => this.#handleListeners(resp))
        if (!this.listeners[dest]) {
          this.listeners[dest] = {}
        }
      }
    }
  }

  /**
   * Maps a listener to it's destination so we can delete them in O(1)
   */
  #addToDelete (uid, dest) {
    if (!this.listenersToDelete[uid]) this.listenersToDelete[uid] = []
    this.listenersToDelete[uid].push(dest)
  }

  /**
  * Dispatch websocket events to the components that have registered listeners.
  */
  #handleListeners (resp) {
    const body = JSON.parse(resp.body)
    const dest = resp.headers.destination
    Object.values(this.listeners[dest]).forEach(({ callback, options }) => {
      callback(body)

      /**
       * Acknowledge the event if the listener has the shouldAck flag set.
       * If the shouldAck flag is set, webservice is expecting the client to acknowledge
       * it has received the event. Therefore, calling ack() will send an ACK frame to
       * the server.
       */
      if (options.shouldAck) {
        resp.ack()
      }
    })
  }
}
