import { defineStore } from 'pinia'
import { workshopApi } from '@/workshop/workshop-api.js'
import { parseDateAndTime } from '@/utils/date-util.js'

function parseDateTime (date, time) {
  // convert the time to 24hr format manually, Safari does
  // not like the use of 'AM' or 'PM' in the time string
  const timeSplit = time.split(' ')
  let hour = timeSplit[0].split(':')[0]
  const min = timeSplit[0].split(':')[1]
  if (timeSplit[1] === 'AM') {
    hour = hour === '12' ? '00' : hour
  } else {
    hour = hour === '12' ? hour : parseInt(hour) + 12
  }
  const timeIn24HourFormat = `${hour}:${min}:00`
  return `${date}T${timeIn24HourFormat}`
}

function parseWorkshopForm (workshop) {
  return {
    title: workshop.title,
    description: workshop.description,
    startDate: parseDateTime(workshop.date, workshop.startTime),
    endDate: parseDateTime(workshop.date, workshop.endTime),
    type: workshop.type,
    price: workshop.price,
    isHoldFee: workshop.isHoldFee,
    procedureId: workshop.procedure,
    address: workshop.address,
    facilitatorsIds: workshop.facilitators.map(facilitator => facilitator.id),
    facultyIds: parseFacultyUsers([...workshop.faculty, ...workshop.facultyAttendees]),
    isInternal: workshop.isInternal
  }
}

function parseFacultyUsers (allFacultyUsersArray) {
  const facultyIds = new Set()
  allFacultyUsersArray.forEach(facultyUser => facultyIds.add(facultyUser.id))
  return Array.from(facultyIds)
}

export const useWorkshopFormStore = defineStore('worskhopForm', {
  state: () => ({
    editWorkshopId: null,
    form: {
      title: '',
      procedure: '',
      type: '',
      date: '',
      startTime: '',
      endTime: '',
      isInternal: false,
      faculty: [],
      facultyAttendees: [],
      description: '',
      facilitators: [],
      price: null,
      isHoldFee: false,
      address: {
        location: '',
        streetAddress: '',
        city: '',
        state: '',
        zipCode: ''
      },
      hasAttendees: false
    }
  }),
  actions: {
    async submitWorkshopForm () {
      const workshop = parseWorkshopForm(this.form)

      const resp = await workshopApi.post('/admin-workshop/create-workshop', workshop)
      return resp.data
    },
    prepareEditWorkshop (workshop) {
      this.editWorkshopId = workshop.id
      this.form.description = workshop.description
      this.form.address = workshop.workshopAddress
      this.form.price = workshop.price.toString()
      this.form.isHoldFee = workshop.isHoldFee
      this.form.type = workshop.workshopType
      this.form.title = workshop.title
      this.form.procedure = workshop.workshopProcedure.id.toString()
      this.form.isInternal = workshop.isInternal
      this.form.facilitators = workshop.facilitators
      this.form.hasAttendees = workshop.hasAttendees

      this.form.faculty = workshop.faculty.filter(user => user.isFaculty)

      const workshopStartDateTime = parseDateAndTime(new Date(workshop.startDate))
      this.form.date = workshopStartDateTime.date
      this.form.startTime = workshopStartDateTime.time

      this.form.endTime = parseDateAndTime(new Date(workshop.endDate)).time
    },
    async updateWorkshop () {
      const editedWorkshop = parseWorkshopForm(this.form)

      await workshopApi.patch(`/admin-workshop/update-workshop/${this.editWorkshopId}`, editedWorkshop)
    },
    setProcedure (procedureId) {
      this.form.procedure = procedureId
    },
    setType (type) {
      this.form.type = type
    },
    setDate (date) {
      this.form.date = date
    },
    setStartTime (time) {
      this.form.startTime = time
    },
    setEndTime (time) {
      this.form.endTime = time
    },
    setLocation (location) {
      this.form.address.location = location
    },
    setFullAddress (address) {
      this.form.address = address
    },
    setAddress (streetAddress) {
      this.form.address.streetAddress = streetAddress
    },
    setCity (city) {
      this.form.address.city = city
    },
    setState (state) {
      this.form.address.state = state
    },
    setZipCode (zipCode) {
      this.form.address.zipCode = zipCode
    },
    setTitle (title) {
      this.form.title = title
    },
    setIsInternal (isInternal) {
      this.form.isInternal = isInternal
    },
    setDescription (description) {
      this.form.description = description
    },
    setPrice (price) {
      this.form.price = price
    },
    setIsHoldFee (isHoldFee) {
      this.form.isHoldFee = isHoldFee
    },
    setFaculty (faculty) {
      this.form.faculty = faculty
    },
    setFacultyAttendees (facultyAttendees) {
      this.form.facultyAttendees = facultyAttendees
    },
    setFacilitators (facilitators) {
      this.form.facilitators = facilitators
    },
    setHasAttendees (hasAttendees) {
      this.form.hasAttendees = hasAttendees
    }
  }
})
