/**
 * This composable enables you to register a callback function that will be called before the route changes. This is
 * useful because the vue-router beforeRouteLeave hook is only called from top-level components, and not from child
 * components.
 *
 * Call useBeforeRouteChange() in the setup hook of a component. Then, in the mounted or created hook of the component,
 * call this.setBeforeRouteChangeCallback(this.myCallback). The callback function will be called with
 * the same arguments as the vue-router beforeRouteLeave hook, allowing you to proceed or cancel the route change.
*/
import { nextTick } from 'process'
import { onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'

export function useBeforeRouteChange () {
  let registeredCallback = null
  let backButtonUsed = false
  let removeHook = null
  const router = useRouter()

  const setBeforeRouteChangeCallback = (callback) => {
    registeredCallback = callback
  }

  nextTick(() => {
    removeHook = router.beforeEach((to, from, next) => {
      if (registeredCallback) {
        // if the arrow buttons were pressed, history.state.current has been mutated.
        // on the other hand, router.push() (like clicking a link) does not mutate
        // history.state.current until next() is called.
        backButtonUsed = to.fullPath === history.state?.current
        registeredCallback(to, from, next, backButtonUsed)
      } else {
        next()
      }
      setTimeout(() => { backButtonUsed = false })
    })
  })

  onBeforeUnmount(() => {
    if (removeHook) removeHook()
  })

  return {
    setBeforeRouteChangeCallback
  }
}
