<template>
  <li :class="classes" @click="setSelectedChip(value)">
    <div>
      <slot></slot>
    </div>
    <BaseButtonIcon
      v-if="closable"
      class="close-btn"
      colorScheme="gray"
      :transparent="false"
      icon="x.svg"
      :small="small"
      @click="$emit('close')"
    />
  </li>
</template>

<script>
import { objectsAreEqual } from '@/utils/obj-util'

export default {
  name: 'BaseChip',
  inject: ['chipGroupValue', 'setSelectedChip'],
  props: {
    value: {
      type: [String, Object, Number],
      required: false
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    },
    colorScheme: {
      type: String,
      required: false,
      default: 'outline',
      validator: (colorScheme) => {
        return ['outline', 'solid-dark', 'solid-grey'].indexOf(colorScheme) !== -1
      }
    },
    closable: {
      type: Boolean,
      required: false
    }
  },
  emits: ['close'],
  computed: {
    active () {
      if (typeof this.value === 'string' && typeof this.chipGroupValue === 'string') {
        return this.value === this.chipGroupValue
      }

      return objectsAreEqual(this.value, this.chipGroupValue)
    },
    classes () {
      const classes = ['base-chip']

      if (this.small) {
        classes.push('small')
      } else {
        classes.push('large')
      }

      if (this.value) {
        classes.push('clickable')
      }

      if (this.small && this.active) {
        classes.push('small-active')
      }

      if (this.active) {
        classes.push(`${this.colorScheme}-active`)
      } else {
        classes.push(this.colorScheme)
      }

      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/chips.scss";

.base-chip {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.close-btn {
  @include chip-close-btn;
}

.small {
  @include chip-small;
}

.small-active {
  color: $neutral-white;
  background-color: $primary-digital-teal-default;
}

.large {
  @include chip;

  border-radius: 12px;
  padding: 12px 16px;
}

.solid-dark {
  @include chip-solid-dark;
}

.solid-grey {
  @include chip-solid-grey;
}

.solid-dark-active {
  background-color: $neutral-white;
  border: 1px solid $primary-digital-teal-default;
  color: $neutral-typography-dark;
  transition: background-color 50ms ease-in;
}

.outline {
  @include outline;
}

.outline-active {
  color: $neutral-white;
  border: 1px solid $primary-digital-teal-default;
  background: $primary-digital-teal-default;
  transition: background-color 50ms ease-in;
  font-family: "Open Sans Semi-Bold";
}

.clickable {
  cursor: pointer;
}
</style>
