<template>
  <form>
    <slot></slot>
  </form>
</template>

<script>
export default {
  provide () {
    return {
      /**
       * Function provided to base input components such as BaseInput.vue to register them
       * here in the 'inputs' array so we can call validations on each of those components
       * when this form gets submitted, informing the parent whether or not the form is valid.
       *
       * @param {Object} component Component instance such as BaseInput.vue
       */
      registerInput: (component) => {
        const input = component
        this.inputs.push(input)
        const allowValidation = this.validateOn === 'input'
        input.setAllowValidation(allowValidation)

        // determine if this form is valid or not then reset the error message
        if (this.validateOn !== 'lazy') {
          input.validate()
          input.resetValidation()
        }
      },
      unregisterInput: (component) => {
        const componentIndex = this.inputs.findIndex(comp => comp === component)
        if (componentIndex !== -1) {
          this.inputs.splice(componentIndex, 1)
        } else {
          this.$log.warn(`Unable to unregister ${component.modelValue} from BaseForm`)
        }
      }
    }
  },
  props: {
    /**
     * Determine whether or not this form's inputs are all valid. If the value
     * is undefined then no validation has taken place yet (possibly because
     * the form's `validateOn` prop is 'lazy') or the form has been reset.
     * Otherwise modelValue is a boolean indicating if validation has passed.
     */
    modelValue: {
      type: Boolean,
      required: false,
      default: undefined
    },
    /**
     * Determine how to handle validation:
     *
     * - 'lazy':  Validation starts only after `validate()` is called. After `validate()`
     *            each new input (like a keystroke) will trigger revalidation.
     * - 'input': Validate as soon as the inputs detect changes without needing to call
     *            validate() first.
     */
    validateOn: {
      type: String,
      required: false,
      default: 'lazy',
      validator: (value) => {
        return ['lazy', 'input'].indexOf(value) !== -1
      }
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      inputs: []
    }
  },
  computed: {
    isValidForm () {
      let isValid = true
      for (const input of this.inputs) {
        if (!input.isValid) {
          isValid = false
        }
      }
      return isValid
    }
  },
  watch: {
    isValidForm (newValue) {
      this.$emit('update:modelValue', newValue)
    }
  },
  methods: {
    validate () {
      let isValid = true
      for (const input of this.inputs) {
        input.setAllowValidation(true)
        const isInputValid = input.validate()
        if (!isInputValid) isValid = false
      }
      this.$emit('update:modelValue', isValid)
      return isValid
    },
    resetValidation () {
      for (const input of this.inputs) {
        input.resetValidation()
      }
      this.$emit('update:modelValue', null)
    },
    reset () {
      for (const input of this.inputs) {
        input.reset()
      }
      this.$emit('update:modelValue', null)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
