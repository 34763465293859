import { defaultErrorProps } from '@/components/alert/props/default-props'

export const errorHandlerMixin = {
  methods: {
    errorHandlerMixin_defaultErrorModal (error, text, title) {
      let errorMessage = typeof error === 'object' ? JSON.stringify(error.message) : error
      const errorProps = defaultErrorProps(error)
      if (text) {
        errorProps.text = text
        errorMessage += ` : ${text}`
      }
      if (title) {
        errorProps.title = title
      }
      this.$log.error(errorMessage)
      this.$baseAlert(errorProps).msgBoxOk()
    }
  }
}
