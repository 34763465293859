<template>
  <!-- Don't use this component directly! Use enqueueToastNotification instead -->
  <transition name="toast" appear>
    <BaseCard :class="['toast', 'toast-container', toastStyleClass[toastType].body]">
      <div :class="['header', toastStyleClass[toastType].header]">
        <span>
          <img
            :class="['icon', toastStyleClass[toastType].iconStyle]"
            :src="toastStyleClass[toastType].icon"
            alt="Notification Status Icon"
          />
        </span>
      </div>
      <div class="container">
        <div @click="onContextClick" data-cy="toast-notif-content">
          <slot></slot>
        </div>
      </div>
      <div class="footer">
        <BaseButtonIcon icon="x.svg" class="footer-btns" data-cy="toast-close-btn" @click="closeToast" />
      </div>
    </BaseCard>
  </transition>
</template>

<script>
export default {
  props: {
    toastType: {
      type: String,
      required: true
    },
    timeout: {
      type: Number,
      required: false
    }
  },
  emits: ['close'],
  data () {
    return {
      toastStyleClass: {
        SUCCESS: {
          header: 'header-success',
          body: 'body-success',
          icon: require('@/assets/icons/toast-success.svg'),
          iconStyle: 'icon-success'
        },
        ERROR: {
          header: 'header-error',
          body: 'body-error',
          icon: require('@/assets/icons/toast-exclamation.svg'),
          iconStyle: 'icon-error'
        },
        DELETE: {
          header: 'header-success',
          body: 'body-success',
          icon: require('@/assets/icons/trash.svg'),
          iconStyle: 'icon-delete'
        },
        SAVED: {
          header: 'header-success',
          body: 'body-success',
          icon: require('@/assets/icons/toast-heart.svg'),
          iconStyle: 'icon-success'
        },
        INFO: {
          header: 'header-info',
          body: 'body-info',
          icon: require('@/assets/icons/toast-info.svg'),
          iconStyle: 'icon-success'
        }
      }
    }
  },
  mounted () {
    if (this.timeout) {
      setTimeout(() => this.closeToast(), this.timeout)
    }
  },
  methods: {
    closeToast () {
      this.$emit('close')
    },
    onContextClick (event) {
      // intercept link click to close toast notif and to navigate with router
      if (event.target.tagName === 'A') {
        event.preventDefault()
        this.$router.push(event.target.pathname)
        this.closeToast()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.base-card.toast {
  position: fixed;
  right: 0;
  bottom: 5%;
  font-family: "Open Sans";
  color: rgba(0, 0, 0, 0.6);
  background-color: $supporting-success-green-light;
}

.toast-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-items: center;
  gap: 28px;
  min-height: 65px;
  width: 380px;
  z-index: 99;
}

.toast-container > * {
  height: 100%;
}

.header {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  border-top-right-radius: 0 !important;
  height: auto;
  align-self: stretch;
}

.header-success {
  background: $supporting-success-green;
}

.body-success {
  background: $supporting-success-green-light;
}

.header-error {
  background: $supporting-danger-red;
}

.body-error {
  background: $supporting-danger-red-light;
}

.header-info {
  background: $supporting-info-blue;
}

.body-info {
  background: $supporting-info-blue-light;
}

.container {
  display: flex;
  flex-direction: column;
}

.icon {
  height: 100%;
  margin: 0 1rem;
}

.footer {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;

  .footer-btns {
    margin-left: auto;
  }
}

.icon-success {
  width: 20px;
}

.icon-error, .icon-close {
  width: 16px;
}

.icon-delete {
  filter:
    brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(4%)
    hue-rotate(195deg) brightness(104%) contrast(100%);
  width: 16px;
}

.toast-enter-active {
  transition: 0.65s;
}

.toast-leave-active {
  transition: 0.5s;
}

.toast-enter-from {
  transform: translate(100%, 0);
}

.toast-leave-to {
  transform: translate(100%, 0);
}

</style>
