export default function scrollBehavior (to, from, savedPosition) {
  if (savedPosition) { // scroll to saved position when navigating with back/forward buttons
    return savedPosition
  }
  if (to.hash) { // scroll to anchor
    return { el: to.hash, behavior: 'smooth' }
  }
  // scroll to top of page for all other navigation
  // Note: this needs `setTimeout` because of a mobile view bug in the hamburger menu navigation.
  // Using the hamburger menu to navigate to a new page will not scroll to the top unless this is async.
  setTimeout(() => { window.scrollTo(0, 0) }, 0)
}
