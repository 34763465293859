import { getCurrentInstance, inject, onMounted, onUnmounted, ref, watch } from 'vue'

export function useInputValidation (props) {
  const registerInput = inject('registerInput', undefined)
  const unregisterInput = inject('unregisterInput', undefined)

  const component = getCurrentInstance().proxy

  onMounted(() => {
    // If this component is a child of BaseForm, register this input with
    // BaseForm so it can call validate() on all its child input elements
    if (registerInput) {
      registerInput(component)
    }

    // Use a watcher on the `required` prop to make it reactive. This allows
    // us to bind the `required` attribute of an input element to a condition
    // that can be toggled after the component is mounted.
    watch(() => props.required, () => {
      if (props.required) {
        hasValidationRules.value = true
      }
    })
  })
  onUnmounted(() => {
    if (unregisterInput) {
      unregisterInput(component)
    }
  })

  const errorMessage = ref(undefined)
  const allowValidation = ref(false)
  const hasValidationRules = ref(false)
  const isValid = ref(true)

  if (props.rules || props.required) {
    hasValidationRules.value = true
  }

  /**
   * Toggle whether or not we show the error message on failed validations.
   *
   * This is toggled by BaseForm.vue based on its `validateOn` prop.
   * E.g., if validateOn === 'lazy' we don't validate until `validate()` is
   * manually called. If validateOn === 'input' we validate as soon as the
   * input values change without needing to submit the form.
   *
   * @param {Boolean} value true to allow validation; false otherwise
   */
  const setAllowValidation = (value) => {
    allowValidation.value = value
  }

  const resetValidation = () => {
    errorMessage.value = undefined
    isValid.value = true
  }

  const validate = () => {
    if (!hasValidationRules.value) return true
    if (!allowValidation.value) {
      isValid.value = true
      return true
    }

    if (props.required && !props.modelValue) {
      errorMessage.value = 'This field is required'
      isValid.value = false
      return false
    }

    if (!props.rules) {
      resetValidation() // reset validation in case the error message from the `required` prop is showing
      return true
    }

    const result = props.rules(props.modelValue)
    if (result === true) {
      resetValidation()
      return true
    }

    if (typeof result !== 'string') {
      console.warn(`Invalid result. Rule function must return \`true\` or a string. Result: ${result}`)
    }

    errorMessage.value = result
    isValid.value = false
    return false
  }

  const reset = () => {
    component.$emit('update:modelValue', undefined)
    resetValidation()
  }

  return {
    errorMessage,
    hasValidationRules,
    isValid,
    setAllowValidation,
    validate,
    resetValidation,
    reset
  }
}
