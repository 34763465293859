import { useNotificationStore } from '@/residency/stores/notification'

const response = {
  '/user/topic/notification/new-asmt': (res) => {
    addNotifInfo(res)
  },
  '/user/topic/notification/shared-library': (res) => {
    addNotifInfo(res)
  },
  '/user/topic/notification/delete': (res) => {
    const notif = JSON.parse(res.body)
    const { deleteNotification } = useNotificationStore()
    deleteNotification(notif)
  },
  '/user/topic/notification/event-delete': (res) => {
    addNotifInfo(res)
  }
}

function addNotifInfo (res) {
  const { addNewWSNotif } = useNotificationStore()
  const notif = JSON.parse(res.body)
  notif.creationDate = new Date()
  addNewWSNotif(notif)
}

export default {
  response
}
