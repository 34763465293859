import { defineStore } from 'pinia'
import { workshopApi } from '@/workshop/workshop-api'
import { DEFAULT_OPTIONS } from '@/consts/filter-default-option'
import { useWorkshopTableFilterStore } from '@/workshop/stores/filters/workshop-table-filter'
import { useWorkshopHistoryCountStore } from '@/workshop/stores/admin/workshop-history-count'

function filterWorkshops (workshop) {
  const filterStore = useWorkshopTableFilterStore()
  const filterMenuOptions = filterStore.filterMenu
  for (const [key, value] of Object.entries(filterMenuOptions)) {
    if (value !== DEFAULT_OPTIONS.ALL && !filterFields(workshop, key, value)) {
      return false
    }
  }
  return true
}

function filterFields (workshop, key, value) {
  if (key === 'procedure') {
    return workshop.procedure === value
  } else if (key === 'facilitator') {
    return workshop.facilitators.includes(value)
  }
  return false
}

export const useWorkshopHistoryStore = defineStore('workshopHistory', {
  state: () => ({
    workshops: {
      pastWorkshops: [],
      upcomingWorkshops: []
    }
  }),
  getters: {
    pastWorkshops: (state) => {
      return state.workshops.pastWorkshops.filter((workshop) => filterWorkshops(workshop))
    },
    upcomingWorkshops: (state) => {
      return state.workshops.upcomingWorkshops.filter((workshop) => filterWorkshops(workshop))
    },
    facilitatorsForPastWorkshops: (state) => {
      return [...new Set(state.workshops.pastWorkshops.reduce((accumulator, workshop) => {
        return accumulator.concat(workshop.facilitators)
      }, []))]
    },
    facilitatorsForUpcomingWorkshops: (state) => {
      return [...new Set(state.workshops.upcomingWorkshops.reduce((accumulator, workshop) => {
        return accumulator.concat(workshop.facilitators)
      }, []))]
    }
  },
  actions: {
    async fetchPastWorkshops () {
      const { setPastWorkshopsTotalCount } = useWorkshopHistoryCountStore()
      const res = await workshopApi.get('/admin-workshop/past')
      this.workshops.pastWorkshops = res.data
      setPastWorkshopsTotalCount(res.data.length)
    },
    async fetchUpcomingWorkshops () {
      const { setUpcomingWorkshopsTotalCount } = useWorkshopHistoryCountStore()
      const res = await workshopApi.get('/admin-workshop/upcoming')
      this.workshops.upcomingWorkshops = res.data
      setUpcomingWorkshopsTotalCount(res.data.length)
    }
  }
})
