<template>
  <BaseButton
    class="submit"
    submit
    data-cy="submit"
    :disabled="disabled"
  >
    SUBMIT
  </BaseButton>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.submit {
  font-family: "Open Sans Semi-Bold";
  font-size: 0.9375rem;
  width: 105px;
  height: 40px;
}
</style>
