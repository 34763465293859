/**
 * This composable uses a store value for the window width, which is set by an event listener
 * on the StandardLayout component. Use the isMobile computed property to check for mobile breakpoints.
 * If you want to have multiple break points in a component, create a computed property
 * that checks the store value.
 *
 * It can be used with the default value for mobile:
 *   const { isMobile } =  useMobileThreshold()]
 *
 * Or it can be used with a pixel value:
 *   const { isMobile }  = useMobileThreshold(1024)]
 *
 * There are also constants defined in consts/mobile-thresholds.js that can be used to avoid magic numbers:
 *   import { mobileThreshold } from '@/consts/mobile-thresholds.js'
 *   const { isMobile } =  useMobileThreshold(mobileThreshold.TABLET)]
 *
 * Constants are available that use the values defined in assets/scss/_variables.scss such as
 * SMALL, MEDIUM, LARGE, EXTRA_LARGE, and MOBILE
 */
import { computed } from 'vue'
import { useLayoutStore } from '@/stores/layout'
import { mobileThreshold } from '@/consts/mobile-thresholds.js'

export function useMobileThreshold (threshold = mobileThreshold.MOBILE) {
  const layoutStore = useLayoutStore()
  const isMobile = computed(() => layoutStore.windowWidth <= threshold)
  return { isMobile }
}
