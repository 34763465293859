import { defineStore } from 'pinia'

export const useVideoPlayerStore = defineStore('videoPlayer', {
  persist: { storage: sessionStorage },
  state: () => ({
    volume: 100,
    previousVolume: 100
  }),
  getters: {
    isMuted () {
      return this.volume === 0
    },
    volumePercentage () {
      return this.volume / 100
    }
  },
  actions: {
    updateVolume (volume) {
      this.volume = volume
    },
    toggleMute () {
      if (this.volume === 0) {
        this.volume = this.previousVolume
      } else {
        this.previousVolume = this.volume
        this.volume = 0
      }
    }
  }
})
