const courses = {
  GUIDE_TO_ASSESSMENT: {
    titleCase: 'Guide to Assessment',
    kebabCase: 'guide-to-assessment'
  },
  FUNDAMENTALS_OF_FEEDBACK: {
    titleCase: 'Fundamentals of Feedback',
    kebabCase: 'fundamentals-of-feedback'
  },
  FIRST_ENTRY: {
    titleCase: 'First Entry',
    kebabCase: 'first-entry'
  },
  CAMERA_NAVIGATION: {
    titleCase: 'Camera Navigation',
    kebabCase: 'camera-navigation'
  },
  LAP_INSTRUMENTATION: {
    titleCase: 'Laparoscopic Instrumentation',
    kebabCase: 'laparoscopic-instrumentation'
  },
  SURGICAL_ENERGY: {
    titleCase: 'Surgical Energy',
    kebabCase: 'surgical-energy'
  },
  TISSUE_HANDLING: {
    titleCase: 'Tissue Handling',
    kebabCase: 'tissue-handling'
  },
  SUTURING: {
    titleCase: 'Laparoscopic Suturing',
    kebabCase: 'laparoscopic-suturing'
  },
  CHOLECYSTECTOMY: {
    titleCase: 'Laparoscopic Cholecystectomy',
    kebabCase: 'laparoscopic-cholecystectomy'
  },
  SALPINGO_OOPHORECTOMY: {
    titleCase: 'Laparoscopic Salpingo-Oophorectomy',
    kebabCase: 'laparoscopic-salpingo-oophorectomy'
  },
  VAGINAL_CUFF_CLOSURE: {
    titleCase: 'Vaginal Cuff Closure',
    kebabCase: 'vaginal-cuff-closure'
  },
  CONTAINED_TISSUE_EXTRACTION: {
    titleCase: 'Contained Tissue Extraction',
    kebabCase: 'contained-tissue-extraction'
  },
  LAP_TUBAL_PROCEDURES: {
    titleCase: 'Laparoscopic Tubal Procedures',
    kebabCase: 'laparoscopic-tubal-procedures'
  },
  OVARIAN_CYSTECTOMY: {
    titleCase: 'Ovarian Cystectomy and Ovarian Torsion',
    kebabCase: 'ovarian-cystectomy-and-ovarian-torsion'
  },
  LAP_APPENDECTOMY: {
    titleCase: 'Laparoscopic Appendectomy',
    kebabCase: 'laparoscopic-appendectomy'
  },
  VAGINAL_HYSTERECTOMY: {
    titleCase: 'Vaginal Hysterectomy',
    kebabCase: 'vaginal-hysterectomy'
  },
  LAPAROSCOPIC_HYSTERECTOMY: {
    titleCase: 'Laparoscopic Hysterectomy',
    kebabCase: 'laparoscopic-hysterectomy'
  },
  LEFT_COLECTOMY: {
    titleCase: 'Left Colectomy',
    kebabCase: 'left-colectomy'
  },
  OSTOMY: {
    titleCase: 'Ostomy',
    kebabCase: 'ostomy'
  },
  ERGONOMICS: {
    titleCase: 'Ergonomics',
    kebabCase: 'ergonomics'
  },
  COMMUNICATION: {
    titleCase: 'Communication',
    kebabCase: 'communication'
  },
  BURNOUT_AND_PHYSICIAN_WELLNESS: {
    titleCase: 'Burnout and Physician Wellness',
    kebabCase: 'burnout-and-physician-wellness'
  },
  VNOTES: {
    titleCase: 'vNOTES',
    kebabCase: 'vnotes'
  },
  COLPOTOMY: {
    titleCase: 'Colpotomy',
    kebabCase: 'colpotomy'
  },
  ANASTOMOTIC_TECHNIQUES: {
    titleCase: 'Anastomotic Techniques',
    kebabCase: 'anastomotic-techniques'
  },
  RESIDENT_ORIENTATION: {
    titleCase: 'Orientation',
    kebabCase: 'orientation'
  },
  FACULTY_ORIENTATION: {
    titleCase: 'Faculty Orientation',
    kebabCase: 'faculty-orientation'
  },
  RENAL_HILUM_DISSECTION: {
    titleCase: 'Renal Hilum Dissection',
    kebabCase: 'renal-hilum-dissection'
  }
}

export const courseEnum = Object.freeze(courses)
