import alertTypes from '@/components/alert/alert-types'

export function defaultErrorProps (error) {
  const defaultProps = { alertType: alertTypes.ERROR }
  if (error) {
    if (!error.response) {
      console.error(error)
      defaultProps.title = 'Network Error'
      defaultProps.text = 'A connection to the server could not be established. The server may be down.'
    } else if (error.response.status >= 500) {
      defaultProps.title = 'Internal Server Error'
      defaultProps.text = 'Please try again later or contact the Simsei team at simsei@appliedmedical.com.'
    } else if (error.response.status === 404) {
      // If the request got a 404 but did not have a message attached from the server,
      // display custom message
      defaultProps.title = 'Not Found'
      defaultProps.text = 'Resource not found.'
    } else if (error.response.status === 451) {
      defaultProps.title = 'Request Blocked'
      defaultProps.text = 'Your request could not be completed. This may be due to a firewall.'
    } else if (error.response.data && error.response.data.message) {
      // Show the status code as title for 400 errors. We can also potentially `switch`
      // on these and give the status code message instead.
      defaultProps.title = error.response.status.toString()
      defaultProps.text = error.response.data.message
    } else {
      defaultProps.title = 'Error'
      defaultProps.text = 'Please try again later or contact the Simsei team.'
    }
  } else {
    console.error(error)
    defaultProps.title = 'Error'
    defaultProps.text = 'An unknown error has occurred. Please try again later or contact the Simsei team.'
  }
  return defaultProps
}
