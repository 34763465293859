<template>
  <div class="datalist-container">
    <BaseInput
      ref="input"
      :modelValue="modelValue"
      :disabled="disabled"
      :required="required"
      :autofocus="autofocus"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :data-cy="dataCy"
      :rules="rules"
      @update:modelValue="updateText"
      @focus="isMenuActive = true"
      @blur="handleBlur"
      @keydown="onKeydown"
    >
      <ul
        v-show="showMenu"
        :class="['list', { 'modal': isModal }]"
        :style="getMaxHeight"
        data-testid="base-input-datalist-menu"
      >
        <li
          v-for="(option, index) in filteredOptions"
          :key="option"
          :class="['list-item', { active: focusedOptionPosition === index }]"
          :data-cy="`base-input-datalist-list-item-${index}`"
          @mousedown="selectOption(option)"
        >
          {{ option }}
        </li>
      </ul>
    </BaseInput>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: false,
      default: () => []
    },
    showMenuAfterTyping: {
      type: Boolean,
      required: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    maxlength: {
      type: Number,
      required: false
    },
    isModal: {
      type: Boolean,
      required: false
    },
    dataCy: {
      type: String,
      required: false
    },
    rules: {
      type: Function,
      required: false
    },
    maxHeight: {
      type: Number,
      required: false
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      text: '',
      isMenuActive: false,
      focusedOptionPosition: -1,
      keyCodes: { ARROW_DOWN: 40, ARROW_UP: 38, ENTER: 13, ESC: 27 }
    }
  },
  computed: {
    // Changes options list based on text input
    filteredOptions () {
      const text = this.text.toUpperCase()
      const hasTextMatch = option => option.toUpperCase().indexOf(text) > -1
      return this.options.filter(hasTextMatch)
    },
    showMenu () {
      if (this.showMenuAfterTyping) return !!this.text.length
      return this.isMenuActive
    },
    getMaxHeight () {
      return this.maxHeight ? `max-height: ${this.maxHeight}px; overflow-y: scroll;` : ''
    }
  },
  watch: {
    /* Repositions focus highlight when the filteredOptions change */
    'filteredOptions.length' () {
      this.adjustFocusPosition()
    }
  },
  methods: {
    handleBlur () {
      this.isMenuActive = !this.isMenuActive
    },
    selectOption (option) {
      this.text = option
      this.$emit('update:modelValue', this.text)
      this.$refs.input.resetValidation()
    },
    onKeydown (event) {
      // prevents focus from moving if menu is not visible
      if (!this.showMenu) return

      if (event.keyCode === this.keyCodes.ARROW_DOWN) this.shiftFocusDown()
      else if (event.keyCode === this.keyCodes.ARROW_UP) this.shiftFocusUp()
      else if (event.keyCode === this.keyCodes.ESC) event.target.blur()
      else if (event.keyCode === this.keyCodes.ENTER) {
        // no options or no focused option
        if (!this.filteredOptions.length || this.focusedOptionPosition <= -1) return

        // select option
        this.text = this.filteredOptions[this.focusedOptionPosition]
        this.$emit('update:modelValue', this.text)
        event.target.blur() // this allows the menu to be expandable again
      }
    },
    shiftFocusUp () {
      this.focusedOptionPosition--
      const hasGoneAboveFirstOption = this.focusedOptionPosition < 0
      if (hasGoneAboveFirstOption) this.focusedOptionPosition = this.filteredOptions.length - 1
    },
    shiftFocusDown () {
      this.focusedOptionPosition++
      const hasGoneBelowLastOption = this.focusedOptionPosition >= this.filteredOptions.length
      if (hasGoneBelowLastOption) this.focusedOptionPosition = 0
    },
    adjustFocusPosition () {
      this.focusedOptionPosition = this.filteredOptions.findIndex(option => option === this.text)
    },
    updateText (text) {
      this.text = text
      this.$emit('update:modelValue', text)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/components/inputs/input.scss";

.datalist-container {
  position: relative;
}

.list {
  @include custom-list-dropdown;

  width: 100%;
}

.modal {
  max-height: 100px;
  overflow-x: auto;
}

.list-item {
  padding: 12px;
  color: $neutral-typography-dark;
  font-size: $font-size-desktop-body;
  cursor: pointer;
}

.list-item:hover, .active {
  color: $primary-digital-teal-default;
  background: $neutral-background-color;
}
</style>
