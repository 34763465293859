import { defineStore } from 'pinia'
import { simseiApi } from '@/residency/app-props'
import viewModes from '@/residency/consts/resident-view-modes'
import userRoles from '@/residency/consts/user-roles'
import userGroupAllOption from '@/residency/consts/user-group-all-option.js'
import { useProctorStore } from '@/residency/stores/proctor'
import { useUserGroupStore } from '@/residency/stores/user-group'
import { useProgramStore } from './program'

export const useMeStore = defineStore('me', {
  persist: { storage: sessionStorage },
  state: () => ({
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    fullName: '',
    role: '',
    isActive: false,
    profileInfo: {},
    timezone: ''
  }),
  getters: {
    isResident: state => state.role === userRoles.RESIDENT,
    isActiveResident: (state) => {
      const proctorStore = useProctorStore()
      return state.role === userRoles.RESIDENT &&
        state.isActive &&
        !proctorStore.isViewingAsProctor
    },
    isAdministrativeRole: (state) => {
      const userRole = state.role
      return userRole === userRoles.SIMSEI_ADMIN ||
        userRole === userRoles.SIMSEI_IMPLEMENTATION_MANAGER ||
        userRole === userRoles.FIELD_TEAM_MEMBER
    },
    isManagerialRole: (state) => {
      const userRole = state.role
      return userRole === userRoles.SIMSEI_ADMIN ||
        userRole === userRoles.SIMSEI_IMPLEMENTATION_MANAGER
    },
    isInstructor (state) {
      const proctorStore = useProctorStore()
      return this.isFaculty || this.isProgramDirector || proctorStore.isViewingAsProctor
    },
    isFaculty: state => state.role === userRoles.FACULTY,
    isProgramDirector: state => state.role === userRoles.PROG_DIRECTOR,
    isAdmin: state => state.role === userRoles.SIMSEI_ADMIN,
    isSystemCoordinator: state => state.role === userRoles.SYS_COORDINATOR
  },
  actions: {
    async requestMe () {
      return simseiApi.get('/me').then(async resp => {
        this.setMe(resp.data)
        const userGroupStore = useUserGroupStore()
        const proctorStore = useProctorStore()
        const { setProgramId } = useProgramStore()
        userGroupStore.setUserGroups(resp.data.userGroups)
        proctorStore.setProctorGroups(resp.data.proctorGroups)

        switch (resp.data.role) {
          case (userRoles.RESIDENT):
            if (resp.data.isActive) {
              userGroupStore.setCurrentUserGroupId(resp.data.currentUserGroupId)
              userGroupStore.setSelectedUserGroup(resp.data.currentUserGroupId)
              userGroupStore.setAsmtHistoryUserGroupId(resp.data.currentUserGroupId)
            } else {
              if (resp.data.userGroups[0]) {
                // inactive residents may not have a currentUserGroup so select a previous one
                // This is so they can login with curricular history
                userGroupStore.setSelectedUserGroup(resp.data.userGroups[0].id)
                userGroupStore.setAsmtHistoryUserGroupId(resp.data.userGroups[0].id)
              }

              // If the resident is inactive but is proctoring active groups,
              // put them into proctor mode for that group. If not,
              // then it will fall back into curricular history mode
              const activeProctorGroup = resp.data.proctorGroups.find(proctorGroup => proctorGroup.isActive)
              if (activeProctorGroup) {
                proctorStore.changeViewType(viewModes.PROCTOR)
                userGroupStore.setSelectedUserGroup(activeProctorGroup.id)
                userGroupStore.setAsmtHistoryUserGroupId(activeProctorGroup.id)
                userGroupStore.setVideoQueueUserGroupId(activeProctorGroup.id)
              }
            }
            setProgramId(resp.data.programId)
            break
          case (userRoles.FACULTY):
          case (userRoles.PROG_DIRECTOR):
            setProgramId(resp.data.programId)
            // Set "all" option as the selected user group
            userGroupStore.setSelectedUserGroup(userGroupAllOption.id)
            break
          default:
            // do nothing
        }
        return resp.data
      }).catch(error => {
        throw error
      })
    },
    setMe (meDto) {
      this.$patch({
        ...meDto,
        fullName: meDto.firstName + ' ' + meDto.lastName
      })
    },
    setProfileInfo (profileInfo) {
      this.profileInfo = profileInfo
    }
  }
})
