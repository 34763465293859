<template>
  <button
    :class="classes"
    v-ripple="!isDisabled"
    :data-cy="$attrs['data-cy']"
    @click="click"
  >
    <img :src="getSrc" :style="iconSizeStyle" />
  </button>
</template>

<script>
export default {
  name: 'BaseButtonIcon',
  props: {
    icon: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    colorScheme: {
      type: String,
      required: false,
      default: 'gray',
      validator: (colorScheme) => {
        // Color schemes can be found in assets/scss/buttons.scss
        return [
          'dark-teal', 'light-teal', 'gray', 'light-red'
        ].indexOf(colorScheme) !== -1
      }
    },
    small: {
      type: Boolean,
      required: false
    },
    transparent: {
      type: Boolean,
      default: true
    },
    cdnPath: {
      type: String,
      required: false
    },
    noHover: {
      type: Boolean,
      required: false,
      default: false
    },
    iconSize: {
      type: Number,
      required: false
    }
  },
  emits: ['click'],
  computed: {
    getSrc () {
      return this.cdnPath || require(`@/assets/icons/${this.icon}`)
    },
    classes () {
      const classes = ['base-btn', 'btn-round', 'btn-icon', this.$attrs.class]

      if (this.disabled) {
        classes.push('btn-transparent-disabled')
      } else if (this.transparent) {
        classes.push(`btn-transparent-${this.colorScheme}`)
      } else {
        classes.push(`btn-contained-${this.colorScheme}`)
      }

      if (this.small) {
        classes.push('btn-icon-small')
      } else {
        classes.push('btn-icon-normal')
      }

      // noHover is used to prevent a mobile style bug where the hover style persists on tap
      if (this.noHover) {
        classes.push('no-hover')
      }

      return classes
    },
    isDisabled () {
      // This property is requried for v-badge
      return this.disabled
    },
    iconSizeStyle () {
      const style = {}
      if (this.iconSize) {
        style.width = `${this.iconSize}px`
        style.height = `${this.iconSize}px`
      }
      return style
    }
  },
  methods: {
    click (e) {
      e.preventDefault()
      if (!this.disabled) {
        this.$emit('click', e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/buttons.scss";

.base-btn {
  padding: 6px;
}

.btn-icon {
  border-radius: 50%;
  margin: 2px;
}

.btn-icon-normal {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}

.btn-icon-small {
  width: 16px;
  height: 16px;
}

.no-hover:hover {
  background-color: transparent;
}
</style>
