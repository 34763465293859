/**
 * Link to course-activities /{activity ID} format
 */
export default {
  FIRST_ENTRY_PREOP_PLANNING: '1',
  FIRST_ENTRY_HASSAN_CUTDOWN: '2',
  FIRST_ENTRY_VERESS_NEEDLE: '11',
  FIRST_ENTRY_OPTICAL_ENTRY: '12',
  CAM_NAV_EQUIPMENT: '3',
  CAM_NAV_ZERO_DEGREE: '4',
  CAM_NAV_VIRTUAL_AR: '41',
  CAM_NAV_VIRTUAL_ANGLED_AR: '42',
  SURGICAL_ENERGY_USE_OF: '21',
  INSTRUMENTATION_OVERVIEW: '6',
  INSTRUMENTATION_PEGBOARD_PERFORMANCE: '5',
  INSTRUMENTATION_PETAL_THREAD: '40',
  SUTURING_SETTING: '7',
  SUTURING_EXTRACORP: '8',
  SUTURING_INTRACORP: '9',
  SUTURING_RUNNING_SUTURE: '10',
  TISSUE_HANDLING_DISSECTION: '16',
  SALPINGO_OOPHORECTOMY_PREOP_PLANNING: '17',
  SALPINGO_OOPHORECTOMY_PERFORMANCE: '18',
  CHOLECYSTECTOMY_PREOP_PLANNING: '19',
  LAP_CHOLE_PERFORMANCE: '20',
  CHOLECYSTECTOMY_PERFORMANCE: '45',
  CHOLECYSTECTOMY_CHOLANGIOGRAPHY: '22',
  VAGINAL_CUFF_CLOSURE_PERFORMANCE: '23',
  VAGINAL_HYSTERECTOMY_PERFORMANCE: '24',
  VAGINAL_HYSTERECTOMY_PREOP_PLANNING: '25',
  LAP_TUBAL_PREOP_PLANNING: '26',
  LAP_TUBAL_PERFORMANCE: '27',
  APPENDECTOMY_PREOP_PLANNING: '28',
  APPENDECTOMY_PERFORMANCE: '29',
  CONTAINED_TISSUE_EXTRACTION_PERFORMANCE: '30',
  OVARIAN_CYSTECTOMY_PREOP_PLANNING: '31',
  OVARIAN_CYSTECTOMY_PERFORMANCE: '32',
  LAP_HYSTERECTOMY_PREOP_PLANNING: '33',
  LAP_HYSTERECTOMY_TOTAL_PERFORMANCE: '34',
  LAP_HYSTERECTOMY_SUMMATIVE_PERFORMANCE: '39',
  VNOTES_VANH_PERFORMANCE: '35',
  VNOTES_ADNEXECTOMY_PERFORMANCE: '36',
  ANTERIOR_COLPOTOMY_PERFORMANCE: '37',
  HAND_ANASTOMOSIS_PERFORMANCE: '38',
  LEFT_COLON_MOBILIZATION_PERFORMANCE: '43',
  COLOSTOMY_PERFORMANCE: '44',
  RENAL_HILUM_DISSECTION: '46'
}
