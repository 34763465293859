import { defineStore } from 'pinia'
import { workshopApi } from '@/workshop/workshop-api.js'
import { paymentStatusEnums } from '@/workshop/consts/workshop-attendee-enum.js'

const selectionModeEnum = {
  emailSelection: 'EMAIL_SELECTION',
  registrationSelection: 'REGISTRATION_SELECTION'
}

function sortAttendee (attendeeA, attendeeB) {
  const aName = `${attendeeA.firstName} ${attendeeA.lastName}`
  const bName = `${attendeeB.firstName} ${attendeeB.lastName}`
  return aName.localeCompare(bName)
}

export const useWorkshopAttendeeMgmtStore = defineStore('workshopAttendeeMgmt', {
  state: () => ({
    workshopId: null,
    selectionMode: null,
    selectedAttendees: [],
    activeAttendees: [],
    inactiveAttendees: [],
    selectedAttendeeDetails: {}
  }),
  getters: {
    isEmailSelectionMode: (state) => {
      return state.selectionMode === selectionModeEnum.emailSelection
    },
    isRegistrationSelectionMode: (state) => {
      return state.selectionMode === selectionModeEnum.registrationSelection
    },
    isSelectionModeActive: (state) => {
      return state.selectionMode !== null
    }
  },
  actions: {
    setEmailSelectionMode () {
      this.resetSelectedAttendees()
      this.setSelectionMode(selectionModeEnum.emailSelection)
    },
    setRegistrationSelectionMode () {
      this.resetSelectedAttendees()
      this.setSelectionMode(selectionModeEnum.registrationSelection)
    },
    exitSelectionMode () {
      this.resetSelectedAttendees()
      this.setSelectionMode(null)
    },
    addUser (user) {
      this.selectedAttendees.push(user)
    },
    removeUser (user) {
      this.setUsersSelected(this.selectedAttendees.filter(selectedUser => selectedUser.email !== user.email))
    },
    async fetchActiveWorkshopAttendees () {
      const res = await workshopApi.get(`/admin-attendee/active/${this.workshopId}`)
      res.data.sort(sortAttendee)
      this.activeAttendees = res.data
    },
    async fetchInactiveWorkshopAttendees () {
      const res = await workshopApi.get(`/admin-attendee/inactive/${this.workshopId}`)
      res.data.sort(sortAttendee)
      this.inactiveAttendees = res.data
    },
    setAttendeeIsActive (payload) {
      return workshopApi.patch(`/admin-attendee/set-is-active/${payload.attendee.id}?isActive=${payload.isActive}`)
    },
    submitNewWorkshopAttendee (payload) {
      return workshopApi.post(`/admin-attendee/add-new-attendee/${payload.workshopId}`, payload.attendee)
    },
    submitExistingWorkshopAttendee (payload) {
      return workshopApi.post(`/admin-attendee/add-existing-attendee/${payload.workshopId}`, payload.attendee)
    },
    processPayment (attendeeId) {
      return workshopApi.post(`/admin-attendee/process-payment/${attendeeId}`)
    },
    sendAttendeeRegistrationEmail (attendeeId) {
      return workshopApi.patch(`/admin-attendee/send-registration-email/${attendeeId}`)
    },
    sendAttendeeRegistrationEmails () {
      const attendeeIds = this.selectedAttendees.map(attendee => attendee.id)
      return workshopApi.patch('/admin-attendee/send-registration-emails', attendeeIds)
    },
    setManageAttendeeWorkshopId (workshopId) {
      this.workshopId = workshopId
    },
    resetSelectedAttendees () {
      this.selectedAttendees = []
    },
    setSelectionMode (selectionMode) {
      this.selectionMode = selectionMode
    },
    setUsersSelected (users) {
      this.selectedAttendees = users
    },
    updatePaymentStatusToPaid (attendeeId) {
      const attendee = this.activeAttendees.find(attendee => attendee.id === attendeeId)
      if (attendee) {
        attendee.paymentStatus = paymentStatusEnums.PAID
      }
    },
    setSelectedAttendeeDetails (attendee) {
      this.selectedAttendeeDetails = attendee
    },
    updateSelectedActiveAttendeeStatus (isActive) {
      this.selectedAttendeeDetails.attendee.isActive = isActive
    },
    updateSelectedAttendeePaymentStatus (paymentStatus) {
      this.selectedAttendeeDetails.attendee.paymentStatus = paymentStatus
    },
    updateSelectedAttendeeRegistrationEmailSentDate (date) {
      this.selectedAttendeeDetails.attendee.registrationEmailSentDate = date
    }
  }
})
