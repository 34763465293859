import { defineStore } from 'pinia'
import { simseiApi } from '@/residency/app-props'
import userGroupAllOption from '@/residency/consts/user-group-all-option.js'
import { useProctorStore } from '@/residency/stores/proctor'

const getGroup = (state, groupId) => {
  const proctorStore = useProctorStore()
  if (proctorStore.isViewingAsProctor) {
    const allProctorGroups = [...proctorStore.proctorGroups, ...proctorStore.expiredProctorGroups]
    return allProctorGroups.find(group => group.id === groupId)
  } else {
    if (groupId === userGroupAllOption.id) return userGroupAllOption
    return state.userGroups.find(group => group.id === groupId)
  }
}

// Choose the first active user group, if there are no active user groups
// then choose the first inactive user group
const chooseActiveOrInactiveUserGroup = (activeUserGroups, allUserGroups) => {
  return activeUserGroups.length
    ? activeUserGroups[0].id
    : allUserGroups[0].id
}

export const useUserGroupStore = defineStore('userGroup', {
  persist: { storage: sessionStorage },
  state: () => ({
    userGroups: [],
    currentUserGroupId: null,
    selectedUserGroupId: null,
    videoQueueUserGroupId: userGroupAllOption.id,
    asmtHistoryUserGroupId: userGroupAllOption.id
  }),
  getters: {
    onCurrentUserGroup: state => state.currentUserGroupId === state.selectedUserGroupId,
    videoQueueUserGroup: state => getGroup(state, state.videoQueueUserGroupId),
    currentUserGroup: state => state.userGroups.find(group => group.id === state.currentUserGroupId),
    selectedUserGroup: state => getGroup(state, state.selectedUserGroupId),
    activeUserGroups: state => state.userGroups.filter(group => group.isActive),
    asmtHistoryUserGroup: state => getGroup(state, state.asmtHistoryUserGroupId),
    selectedAllUserGroupId (state) {
      // When "All groups" option is set, we select the first item (user group) from the dropdown list for the dashboard and the courses page.
      // Hence we are grabbing the first item from the active user groups.
      if (state.selectedUserGroupId === userGroupAllOption.id) {
        return chooseActiveOrInactiveUserGroup(this.activeUserGroups, state.userGroups)
      } else {
        return state.selectedUserGroupId
      }
    }
  },
  actions: {
    setUserGroups (userGroups) {
      if (userGroups) {
        this.userGroups = userGroups
      }
    },
    setCurrentUserGroupId (groupId) {
      this.currentUserGroupId = groupId
    },
    setSelectedUserGroup (groupId) {
      this.selectedUserGroupId = groupId
    },
    async fetchUserGroupById (groupId) {
      const res = await simseiApi.get(`/user-group/${groupId}`)
      return res.data
    },
    setVideoQueueUserGroupId (groupId) {
      this.videoQueueUserGroupId = groupId
    },
    setAsmtHistoryUserGroupId (groupId) {
      this.asmtHistoryUserGroupId = groupId
    }
  }
})
