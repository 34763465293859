import { defineStore } from 'pinia'

export const useToastNotificationStore = defineStore('toastNotification', {
  state: () => ({
    toastNotifications: []
  }),
  getters: {
    hasToastNotification: state => state.toastNotifications.length > 0
  },
  actions: {
    fetchNextToastNotification () {
      return this.toastNotifications[0]
    },
    popFrontToastNotification () {
      this.toastNotifications.shift()
    },
    /*
    The argument passed to this function describes information about the toast
    notification to be displayed.
    {
      msg: <String> (literal html),
      timeout: <Number>,
      type: <String> ('SUCCESS' || 'ERROR'),
      onClose: <Function>,
      onOpen: <Function>
      }

      For example:
      enqueueToastNotification(
        {
          msg: 'SAMPLE MESSAGE <a href="https://www.google.com">CLICK HERE</a>',
          useUnsafeHTML: true // (Default value is false)
          timeout: 6000,
          type: 'SUCCESS',
          onClose: () => {
            console.log('Toast notification closed')
          }
        })
      Would enqueue a toast notification that displays 'SAMPLE MESSAGE' and
      has text 'CLICK HERE' which links to 'https://www.google.com'.
      The toast notification would be displayed for 6 seconds. When the toast
      notification is closed (either by timeout or after being manually closed),
      the function onClose() would be called.

      === SECURITY NOTE BELOW ===

      NOTE: in order to use raw html inside the toast notification description,
      you will need to set the "useUnsafeHTML" argument for
      enqueueToastNotification to true. Please be aware this is a SECURITY risk
      if you allow arbitrary/user-specified input be displayed in a toast
      notification. If useUnsafeHTML === false, the "msg" argument will be
      rendered as a string literal in the toast notification.
    */
    enqueueToastNotification (notif) {
      this.toastNotifications.push(notif)
    }
  }

})
