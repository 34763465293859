<template>
  <div class="input-error" data-cy="input-error">{{ message }}</div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.input-error {
  color: $supporting-danger-red;
  font-size: 12px;
  line-height: 12px;
  height: 14px;
}
</style>
