import { defineStore } from 'pinia'
import { useProctorStore } from '@/residency/stores/proctor'
import { useMeStore } from '@/residency/stores/me'
import userRoles from '@/residency/consts/user-roles'

export const useAlertStore = defineStore('alert', {
  state: () => ({
    proctorBannerEnabled: false,
    notificationOverlayEnabled: true
  }),
  getters: {
    showProctorBanner: (state) => {
      const proctorStore = useProctorStore()
      return state.proctorBannerEnabled && proctorStore.isViewingAsProctor
    },
    showNotificationOverlay: (state) => {
      const meStore = useMeStore()
      // Only show notification bell to residents, faculty, and directors
      if (![userRoles.RESIDENT, userRoles.PROG_DIRECTOR, userRoles.FACULTY].includes(meStore.role)) {
        return false
      }
      return state.notificationOverlayEnabled
    }
  },
  actions: {
    setShowProctorBanner (value) {
      this.proctorBannerEnabled = value
    }
  }
})
