import { defineStore } from 'pinia'
import { simseiApi } from '@/residency/app-props'

export const useProgramStore = defineStore('program', {
  persist: { storage: sessionStorage },
  state: () => ({
    programId: null,
    programs: [],
    selectedProgramId: null,
    programSurgicalProcedures: [],
    specialties: []
  }),
  actions: {
    setProgramId (programId) {
      this.programId = programId
    },
    async getSpecialties () {
      const response = await simseiApi.get('/specialty')
      const specialties = response.data._embedded.specialty
      this.specialties = specialties
      return specialties
    },
    async getTimezones () {
      const response = await simseiApi.get('/timezone')
      return response.data._embedded.timezone_option
    },
    async getProgramSurgicalProcedures () {
      if (this.programSurgicalProcedures.length) return this.programSurgicalProcedures

      const response = await simseiApi.get('/video/surgical/procedures')
      const procedures = response.data
      this.programSurgicalProcedures = procedures

      return procedures
    }
  }
})
