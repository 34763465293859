import logTypes from '@/consts/log-types'
import errorMessages from '@/consts/error-messages'

class Log {
  /**
   * Construct a singleton log object with the postLogCallback function that will be used to post the log object
   * to the webservice. This class shouldn't be used directly by a component to send log events. Each subproject
   * should create an instance of this class with it's specialized postLogCallback function. The project
   * specific instances are in [project]/utils/log.js.
   *
   * @param {function} postLogCallback A function that will submit the error object to the backend. Since this is a singleton,
   * subsequent calls to the constructor will not replace the postLogCallback function.
   */
  constructor (postLogCallback) {
    if (Log.instance) {
      return Log.instance
    }
    Log.instance = this
    this.postLog = postLogCallback
  }

  error (err) {
    if (err.message === errorMessages.AUTH_TOKEN_EXPIRED) {
      // no need to log expired/invalid auth tokens on the login page
      return
    }
    console.error(err)
    const errorMessage = typeof err === 'object' ? JSON.stringify(err.message) : err
    const errorName = typeof err === 'object' ? JSON.stringify(err.name) : 'N/A'
    const error = this.setLogObject(errorMessage, logTypes.ERROR, errorName)
    this.postLog(error)
  }

  warn (msg) {
    console.warn(msg)
    const warning = this.setLogObject(msg, logTypes.WARN)
    this.postLog(warning)
  }

  info (msg) {
    console.info(msg)
    const info = this.setLogObject(msg, logTypes.INFO)
    this.postLog(info)
  }

  debug (msg) {
    console.debug(msg)
    const debug = this.setLogObject(msg, logTypes.DEBUG)
    this.postLog(debug)
  }

  setLogObject (message, type, name = 'N/A') {
    return {
      name,
      message,
      type,
      source: window.location.href,
      userAgent: navigator.userAgent
    }
  }
}

export default Log
