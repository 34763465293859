<template>
  <div class="base-card-text">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseCardText'
}
</script>

<style scoped lang="scss">
.base-card-text {
  padding: 1rem;
}
</style>
